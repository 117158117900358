import axios from "axios";
import store from "../store";
import { serverlink } from "../enviroment";
import {
  logout as logoutAction,
  loginOn,
  loadUser,
  setAddresses
} from "../store/actions/userActions";
import { loadingOff } from "../store/actions/triggerActions";

export const login = user => {
  return axios
    .post(
      serverlink + "/api/login",
      {
        email: user.email,
        password: user.password
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
    .then(response => {
      localStorage.setItem("key", response.data.success.token);
      store.dispatch(loginOn());
      store.dispatch(loadingOff());
      return {
        success: true
      };
    })
    .catch(err => {
      return { error: true };
    });
};

export const loadUserData = () => {
  return axios
    .post(serverlink + "/api/details", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key")
      }
    })
    .then(response => {
      store.dispatch(loadUser(response.data.success, response.data.services));
      store.dispatch(setAddresses(response.data.addresses));
      store.dispatch(loginOn());
      return {
        success: true
      };
    })
    .catch(err => {
      store.dispatch(logoutAction());
      return { error: true };
    });
};

export const logout = () => {
  return axios
    .post(serverlink + "/api/logout", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key")
      }
    })
    .then(response => {
      store.dispatch(logoutAction());
      return {
        success: true
      };
    });
};

export const register = data => {
  return axios
    .post(serverlink + "/api/admin/createuser", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      store.dispatch(loadingOff());
      return {
        success: true
      };
    })
    .catch(err => {
      store.dispatch(loadingOff());
      return { success: false, msg: err.response.data.error };
    });
};
