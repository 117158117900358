import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { login } from "./../../../functions/AuthFunctions";
import { loadUserData } from "./../../../functions/AuthFunctions";
import Swal from "sweetalert2-react";
import { connect } from "react-redux";
import Back from "../../Back";
import { loadingOn, loadingOff } from "../../../store/actions/triggerActions";
import { loginPage, loginOff } from "../../../store/actions/userActions";
import { facebookAuthLink } from "../../../strings";

class Login extends Component {
  state = {
    loading: false
  };
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      error: false,
      loading: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {
    if (!this.props.st_loginPage) {
      this.props.loginOn();
    }
  }

  componentWillUnmount() {
    if (this.props.st_loginPage) {
      this.props.loginOff();
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    this.props.loadingOn();
    const user = {
      email: this.state.email,
      password: this.state.password
    };

    login(user)
      .then(res => {
        loadUserData();
        this.props.loadingOFF();
        if (res.error) {
          this.setState({
            error: true
          });
        }
      })
      .catch(err => {
        this.setState({
          error: true
        });
      });
  }

  render() {
    let auth = null;
    if (this.props.st_loggedIn) {
      if (this.props.st_cartLinked) {
        auth = <Redirect to="/confirmar-compra"></Redirect>;
      } else {
        auth = <Redirect to="/"></Redirect>;
      }
    }
    return (
      <div className="card sh-b text-center">
        {auth}
        <div className="card-title text-center mb-20 fs-30  bolder">
          <br />
          Iniciar Sesión
        </div>
        <div className="card-body text-center">
          <form noValidate onSubmit={this.onSubmit}>
            <i className="fa fa-user" style={{ marginRight: "20px" }}></i>
            <input
              name="email"
              type="text"
              placeholder="Usuario"
              className="mb-10"
              value={this.state.email}
              onChange={this.onChange}
            />
            <br />
            <i className="fa fa-chain" style={{ marginRight: "20px" }}></i>
            <input
              name="password"
              type="password"
              placeholder="Contraseña"
              className="mb-10"
              value={this.state.password}
              onChange={this.onChange}
            />
            <br />
            <Link to="/forget" className="mb-10">
              Olvide mi contraseña
            </Link>
            <br />
            <button className="btn btn-negro mb-10 mt-10">Entrar</button>
            <p>Iniciar sesión con:</p>
          </form>
          <a href={facebookAuthLink} className="btn-facebook">
            <i className="fa fa-facebook"></i> Iniciar sesión con Facebook
          </a>
        </div>
        <div className="card-footer text-center mt-20">
          <strong>Aún no tengo cuenta</strong> <br />
          <Link to="/register">Registrarme</Link>
        </div>
        <Swal
          show={this.state.error}
          title="Error"
          text="Revisa los datos de ingreso."
          icon="error"
          onConfirm={() => this.setState({ error: false })}
        ></Swal>
        <Back style={{ textAlign: "center", margin: "auto 0" }}></Back>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    st_loading: state.information.loading,
    st_loggedIn: state.user.loggedIn,
    st_cartLinked: state.information.linkedCart,
    st_loginPage: state.information.loginPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
    loginOn: () => dispatch(loginPage()),
    loginOff: () => dispatch(loginOff())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
