import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Whatsapp from "../../img/whatsapp.png";
import { sendEvent } from "../../functions/GAFunctions";

class BottomPanel extends Component {
  render() {
    return (
      <div className="bottom-panel visible-xs">
        <div className="grid">
          <Link
            to="/tienda"
            style={{ color: "black" }}
            onClick={() => sendEvent("Tienda_menu_inferior")}
          >
            <div className="item">
              <i className="fa fa-wrench"></i> <br /> Tienda
            </div>
          </Link>
          <Link
            to="/historial"
            style={{ color: "black" }}
            onClick={() => sendEvent("Solicitudes_menu_inferior")}
          >
            <div className="item">
              <i className="fa fa-list-ul"></i> <br /> Solicitudes
            </div>
          </Link>
          <Link to="/" style={{ color: "black" }}>
            <div className="item">
              <i className="fa fa-home"></i> <br /> Inicio
            </div>
          </Link>
          <div className="item">
            <Link
              to="/confirmar-compra"
              style={{ color: "black" }}
              onClick={() => sendEvent("Carrito_menu_inferior")}
            >
              <i className="fa fa-shopping-cart"></i>
              {this.props.st_listCart.length +
                this.props.st_listProductCart.length >
                0 && (
                <span
                  className="badge badge-warning"
                  id="lblCartCount"
                  style={{ background: "red" }}
                >
                  {" "}
                  {this.props.st_listCart.length +
                    this.props.st_listProductCart.length}{" "}
                </span>
              )}
            </Link>
            <br />
            Carrito
          </div>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=573217750455"
            style={{ textTransform: "none", color: "black" }}
            onClick={() => sendEvent("Whatsapp")}
          >
            <div className="item">
              <img
                src={Whatsapp}
                style={{ maxWidth: "40px", marginBottom: "-7px" }}
                alt="whatsapp"
              ></img>{" "}
              <br /> Whatsapp
            </div>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_listCart: state.cart.currentServices,
    st_listProductCart: state.cart.currentProducts,
  };
};
export default connect(mapStateToProps)(BottomPanel);
