import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { serverlink } from "../../enviroment";
import { Carousel } from "react-responsive-carousel";
import garantizado from "../../img/garantizado.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { selectCategory } from "../../store/actions/informationActions";
import inmediatez from "../../img/inmediatez.jpg";
import precios from "../../img/precios.jpeg";
import garantia from "../../img/garantia.jpeg";
import seguridad from "../../img/seguridad.jpeg";
import garantizado2 from "../../img/garantizado.jpeg";
import seguros from "../../img/seguros.jpeg";
import paso1 from "../../img/1-1.png";
import paso2 from "../../img/1-2.jpeg";
import paso3 from "../../img/1-3.jpeg";
import paso4 from "../../img/1-4.jpeg";
import paso5 from "../../img/1-5.jpeg";
import celular from "../../img/celular.jpeg";
import logoGrande from "../../img/logo-grande.png";
import footerServicios from "../../img/footer-servicios.jpeg";
import footerLlamanos from "../../img/footer-llamanos.jpeg";
import footerEscribenos from "../../img/footer-escribenos.jpg";
import footerSiguenos from "../../img/footer-siguenos.jpg";
import GridCategories from "./GridCategories";
import { IonButton } from "@ionic/react";

class CategoriesComponent extends Component {
  state = {
    styles: {},
    row1: false,
    row2: false,
  };

  componentDidMount() {}
  render() {
    let redirect = null;
    if (this.props.st_linkedCart && this.props.st_loggedIn) {
      redirect = <Redirect to="/confirmar-compra"></Redirect>;
    }
    const bannersWeb = (
      <div className="hidden-xs" style={{ marginTop: "16px" }}>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showIndicators={false}
        >
          {this.props.st_bannersWeb.map((item) => (
            <div key={item.id}>
              <img style={{}} src={item.image} alt={"banner" + item.id} />
            </div>
          ))}
        </Carousel>
      </div>
    );
    const bannersResponsive = (
      <div className="visible-xs">
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showIndicators={false}
        >
          {this.props.st_bannersResponsive.map((item) => (
            <div key={item.id}>
              <img src={item.image} alt={"banner" + item.id} />
            </div>
          ))}
        </Carousel>
      </div>
    );

    return (
      <>
        <section>
          <div className="row" id="main" style={{ marginTop: "-17px" }}>
            {redirect}
            <div className="col-sm-12 col-md-12 no-padding" id="content">
              {bannersResponsive}
              {bannersWeb}
            </div>
            <div className="col-sm-12 col-md-12 p-20" id="content">
              <div className="text-center">
                <span
                  style={{
                    padding: "5px 20px",
                    color: "#000",
                  }}
                  className=" fs-40 hidden-xs bolder text-center center"
                >
                  Escoge tú servicio
                </span>
                <div className="mt-40 servicios mtr-20">
                  <div className="row mb-20">
                    <div className="col-md-10 col-md-offset-1">
                      <div className="row">
                        {this.props.st_menuList.map((item, index) => (
                          <GridCategories
                            item={item}
                            index={index}
                            key={index}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="text-center visible-xs">
                    <img
                      src={garantizado}
                      style={{ width: "125px" }}
                      alt="garantizado"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{ backgroundColor: "#f2bb30" }}
          className="pb-40 hidden-xs"
        >
          <div className="row">
            <div className="col-md-12 pt-20 mt-20 no-padding">
              <h3 className="fs-40 bolder text-center">
                ¿Por qué HomePartner?
              </h3>
            </div>
            <div className="col-md-10 col-md-offset-1">
              <div className="row mt-40">
                <div className="col-md-4">
                  <img
                    src={inmediatez}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">Inmediatez</h5>
                  <p className="text-center">
                    Sabemos que hay tareas que no pueden esperar! Por eso
                    después de que tomas el servicio el HOME PARTNER tiene un
                    tiempo estimado para llegar.
                  </p>
                </div>
                <div className="col-md-4">
                  <img
                    src={precios}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">
                    Precios Justos
                  </h5>
                  <p className="text-center">
                    HOME PARTNER hace un monitoreo de precios del mercado para
                    cobrar lo justo.
                  </p>
                </div>
                <div className="col-md-4">
                  <img
                    src={garantia}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">Garantía</h5>
                  <p className="text-center">
                    Garantía de 3 hasta de 6 meses, dependiendo del trabajo
                    realizado.
                  </p>
                </div>
              </div>

              <div className="row mt-40">
                <div className="col-md-4">
                  <img
                    src={seguridad}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">Seguridad</h5>
                  <p className="text-center">
                    HOME PARTNER hace un estricto filtro de antecedentes
                    personales y competencias laborales a nuestros profesionales
                    para garantizarle a nuestro cliente su tranquilidad.
                  </p>
                </div>
                <div className="col-md-4">
                  <img
                    src={garantizado2}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">
                    Servicio 100% garantizado
                  </h5>
                  <p className="text-center">
                    Su concepto nos interesa. Si no está 100% satisfecho,
                    trabajaremos para solucionarlo.
                  </p>
                </div>
                <div className="col-md-4">
                  <img
                    src={seguros}
                    className="img-responsive centrado"
                    alt="reloj"
                  />
                  <h5 className="fs-20 text-center f600 mt-20">Seguros</h5>
                  <p className="text-center">
                    Contamos con una Póliza de seguros, que cubre daños a
                    terceros hasta por 10 millones de pesos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-40 pb-40 hidden-xs">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center mt-40">
                <span
                  style={{
                    backgroundColor: "#f2bb30",
                    padding: "5px 20px",
                    color: "#000",
                  }}
                  className="b-amarrillo fs-40 bolder text-center center"
                >
                  ¿Cómo funciona?
                </span>
              </div>
              <div className="row mt-40">
                <div className="col-md-2 col-md-offset-1">
                  <img
                    className="img-responsive centrado"
                    alt="paso1"
                    src={paso1}
                  />
                  <p className="text-center f600 mt-10">
                    1. Selecciona la categoría que necesitas
                  </p>
                </div>
                <div className="col-md-2">
                  <img
                    className="img-responsive centrado"
                    alt="paso2"
                    src={paso2}
                  />
                  <p className="text-center f600 mt-10">
                    2. Selecciona el servicio que necesitas
                  </p>
                </div>
                <div className="col-md-2">
                  <img
                    className="img-responsive centrado"
                    alt="paso3"
                    src={paso3}
                  />
                  <p className="text-center  f600 mt-10">
                    3. Elige si es inmediato o programado
                  </p>
                </div>
                <div className="col-md-2">
                  <img
                    className="img-responsive centrado"
                    alt="paso4"
                    src={paso4}
                  />
                  <p className="text-center  f600 mt-10">
                    4. Elige tu método de Pago
                  </p>
                </div>
                <div className="col-md-2">
                  <img
                    className="img-responsive centrado"
                    alt="paso5"
                    src={paso5}
                  />
                  <p className="text-center f600 mt-10">
                    5. Un técnico estará en tu casa en el tiempo solicitado
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          style={{ backgroundColor: "#f9f9f9" }}
          className="pb-40 hidden-xs"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 pt-20 mt-20 no-padding text-center">
                <span
                  style={{
                    backgroundColor: "#f2bb30",
                    padding: "5px 20px",
                    color: "#000",
                  }}
                  className="b-amarrillo fs-40 bolder text-center center"
                >
                  Ellos nos recomiendan
                </span>
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-md-6 text-center">
                <iframe
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmateo.ruiz.39%2Fposts%2F4246402545384720&width=500"
                  width="500"
                  height="200"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
              <div className="col-md-6">
                <iframe
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnayive.perez%2Fposts%2F10157178357791247&width=500"
                  width="500"
                  height="142"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 text-center">
                <iframe
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D737069440363522%26id%3D100021814182786&width=500"
                  width="500"
                  height="218"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
              <div className="col-md-6">
                <iframe
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcamilo.cardenas.58%2Fposts%2F10157040001896691&width=500"
                  width="500"
                  height="199"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section className="hidden-xs">
          <div className="row">
            <div
              className="col-md-6"
              style={{
                backgroundImage:
                  "url('https://platform.homepartner.com.co/img/celular.jpeg')",
                minHeight: "80vh",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="col-md-6 pt-40 pb-40"
              style={{ backgroundColor: "black", minHeight: "80vh" }}
            >
              <img
                className="img-responsive centrado mt-40"
                alt="homepartner"
                src={logoGrande}
              />
              <div className="row">
                <div className="col-md-6 col-md-offset-3 text-center mt-40">
                  <p style={{ color: "white" }} className="mb-40">
                    Home Partner ! Somos la Plataforma de arreglos locativos más
                    innovadora del país!. Pintura, Instalaciones,
                    Plomería,Electricidad y más. <br />
                    <strong>¡Agenda tu Servicio!</strong>
                  </p>
                  <Link to="/sugerencias">
                    <IonButton>Contáctanos</IonButton>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-40 pb-40 hidden-xs"
          style={{ backgroundColor: "#1f1f1f" }}
        >
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="row">
                <div className="col-md-3 text-center blanco">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <img
                        alt="servicios-hogar"
                        src={footerServicios}
                        className="img-responsive centrado"
                      />
                    </div>
                  </div>
                  <p className="text-center blanco mt-20 f600">Servicios</p>
                  <Link to="/categoria/13/subcategoria/2" className="blanco">
                    {" "}
                    Plomería{" "}
                  </Link>
                  |
                  <Link to="/categoria/13/subcategoria/2" className="blanco">
                    {" "}
                    Electricidad{" "}
                  </Link>
                  |
                  <Link to="/categoria/13/subcategoria/2" className="blanco">
                    {" "}
                    Pintura{" "}
                  </Link>
                  |
                  <Link to="/categoria/13/subcategoria/2" className="blanco">
                    {" "}
                    Gas{" "}
                  </Link>
                  |
                  <Link to="/categoria/13/subcategoria/2" className="blanco">
                    {" "}
                    Instalación de televisores
                  </Link>
                </div>

                <div className="col-md-3 text-center blanco">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <img
                        alt="servicios-hogar"
                        src={footerLlamanos}
                        className="img-responsive centrado"
                      />
                    </div>
                  </div>
                  <p className="text-center blanco mt-20 f600">Llámanos</p>
                  <p>
                    346 3737 <br />
                    Whatsapp: 321 775 04 55
                  </p>
                </div>

                <div className="col-md-3 text-center blanco">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <img
                        alt="servicios-hogar"
                        src={footerEscribenos}
                        className="img-responsive centrado"
                      />
                    </div>
                  </div>
                  <p className="text-center blanco mt-20">Escríbenos</p>
                  <p>
                    contacto@homepartner.com.co
                    <br />
                    homepartner.co@gmail.com
                  </p>
                </div>

                <div className="col-md-3 text-center blanco">
                  <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                      <img
                        alt="servicios-hogar"
                        src={footerSiguenos}
                        className="img-responsive centrado"
                      />
                    </div>
                  </div>
                  <p className="text-center blanco mt-20">Siguenos</p>
                  <p>
                    <a
                      className="blanco"
                      href="https://www.facebook.com/homepartnercolombia/"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>{" "}
                    <a
                      className="blanco"
                      href="https://www.facebook.com/homepartnercolombia/"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>{" "}
                    <a
                      className="blanco"
                      href="https://www.facebook.com/homepartnercolombia/"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_menuList: state.information.listCategories,
    st_linkedCart: state.information.linkedCart,
    st_loggedIn: state.user.loggedIn,
    st_bannersWeb: state.templates.bannersWeb,
    st_bannersResponsive: state.templates.bannersResponsive,
  };
};

const setStateToProps = (dispatch) => {
  return {
    selectCategory: (categorySelected) =>
      dispatch(selectCategory(categorySelected)),
  };
};
export default connect(mapStateToProps, setStateToProps)(CategoriesComponent);
