import React, { Component } from "react";

class Term extends Component {
  render() {
    return (
      <div className="row" id="main">
        <div className="col-md-10 col-md-offset-1">
          <article>
            <h3 className="text-center f2">
              <strong>TERMINOS Y CONDICIONES</strong>
            </h3>
            <p>
              A través de los términos y condiciones, se regulará el uso de los
              servicios y productos de la marca, para la APP y sitios web.
              Dichas condiciones constituyen un acuerdo vinculante entre
              cualquier usuario a cualquier título y HOME PARTNER S.A.S., se
              consideran aceptadas por parte del usuario cada vez que este
              acceda a cualquiera de los productos, por lo que se recomienda
              leer y entender todas las condiciones generales y demás documentos
              de términos de uso.
              <br />
              <br />
              <strong>I. INFORMACIÓN LEGAL</strong>
              <br />
              Al aceptar estas Condiciones Generales usted manifiesta que es
              mayor de edad, que tiene plena capacidad de obrar, que ha leído y
              comprendido las Condiciones de uso que a continuación se
              desarrollan y los términos legales que involucra como la política
              de privacidad o la política de cookies:
              <br />
              El titular de este sitio web es HOME PARTNER S.A.S., (en adelante
              “HOME PARTNER”), identificada con NIT 901.306.165-1 y domicilio
              principal en la Cra 16ª N° 85-32 Of. 101 de Bogotá. HOME PARTNER
              pone a disposición del público en general el uso de su red de
              sitios web, aplicaciones – app – y otros productos con el objeto
              de dar a conocer los servicios y actividades que realiza HOME
              PARTNER, haciéndole saber que desde el momento en que usted acceda
              a esta Página Web o a cualquier otra de su red de sitios web, app
              y otros productos, queda sujeto a los siguientes Términos y
              Condiciones.
              <br />
              Esta información legal se completa con la Política de Protección
              de Datos Personales, con las Condiciones Generales de Uso del
              sitio web de HOME PARTNER y, en su caso, con las Condiciones
              Particulares de cada producto o servicio recogidas en cualquiera
              de los sitios web de HOME PARTNER.
              <br />
              Todo el contenido de los sitios web, app y demás productos son
              propiedad de HOME PARTNER, con la excepción de las imágenes,
              sonidos e infografías utilizadas bajo licencia, quedando
              reservados todos los derechos. Se prohíbe su reproducción total o
              parcial. Cualquier utilización de los mismos contraria a las
              normas en materia de propiedad intelectual (derechos de autor,
              propiedad industrial y demás), será perseguida con arreglo a la
              legislación vigente en Colombia o en las jurisdicciones en las que
              dichas violaciones se concreten a elección de HOME PARTNER.
              <br />
              <br />
              <strong>II. INFORMACIÓN TÉCNICA</strong>
              <br />
              Los productos de HOME PARTNER están optimizados para ser
              visualizados en dispositivos nativos y en definiciones responsive
              (en definiciones menores aparecerán barras de desplazamiento tanto
              horizontal como verticalmente respectivamente).
              <br />
              Para la visualización del sitio web, se recomienda la utilización
              de Firefox 3.0 (o versiones posteriores) o Chrome 4.1 (o versiones
              posteriores). El uso de versiones anteriores de navegador, la
              utilización de un navegador no compatible o la desactivación de
              algunas propiedades, como la compatibilidad con Javascript o la
              desactivación de las cookies, puede reducir la funcionalidad del
              sitio.
              <br />
              Para el uso de la APP HOME PARTNER, se recomienda Nivel de API:
              21. Android 5.0 (LOLLIPOP) o iOS 11.
              <br />
              Si se accede al sitio mediante un firewall (cortafuegos) o
              servidor proxy, asegúrese que permite la recepción de cookies.
              <br />
              <br />
              <strong>III. TÉRMINOS Y CONDICIONES DE USO GENERAL</strong>
              <br />
              1. Condiciones Generales
              <br />
              Estas Condiciones Generales regulan el uso de los sitios web
              pertenecientes y gestionados por HOME PARTNER. Algunos de nuestros
              sitios web podrán recoger sus propias Condiciones Particulares
              que, según los casos completarán, modificarán o sustituirán las
              presentes Condiciones Generales. La utilización de cualquiera de
              los sitios web de HOME PARTNER supone la comprensión y aceptación
              plena de todas y cada una de las Condiciones Generales aquí
              recogidas así como de las Condiciones Particulares que, en su
              caso, se establezcan en algunos sitios web.
              <br />
              2. Registro de usuarios
              <br />
              Para el acceso y/o utilización de determinados servicios y/o
              productos bajo registro, el usuario deberá asignar un nombre de
              usuario así como una contraseña, comprometiéndose a hacer un uso
              diligente, responsable y ajustado a la ley de ambos (nombre de
              usuario y contraseña), y a mantener el secreto de las mismas,
              respondiendo así de todos los daños y/o perjuicios que el uso
              incorrecto, inadecuado e ilícito cause. En cualquier momento, y
              sin necesidad de previo aviso, HOME PARTNER podrá denegar o
              retirar la utilización de nuestros sitios web cuando el usuario
              incumpla con estas las Condiciones Generales o con las Condiciones
              Particulares, según corresponda.
              <br />
              3. Protección de Datos
              <br />
              Para poder utilizar algunos de nuestros sitios web, app y demás
              productos de manera eficiente y segura, los usuarios deberán
              aportar ciertos datos dependiendo de su rol dentro del producto,
              entre ellos pueden figurar: su nombre y apellido, documento de
              identidad, o cualquier otra identificación tributaria o
              previsional, profesión o actividad comercial, experiencia laboral,
              certificaciones laborales, dirección, número de teléfono y cuenta
              de e-mail, sin los cuales se tornaría imposible brindar los
              servicios. Por eso se requiere que éstos sean verdaderos y
              exactos. Los datos recabados por los formularios correspondientes
              serán objeto de tratamiento directamente por HOME PARTNER y/o
              terceros.
              <br />
              4. Gratuidad y no exigencia de suscripción
              <br />
              El acceso y/o utilización de nuestros sitios web y APP de HOME
              PARTNER es gratuito, excepto para aquellos servicios que así se
              señalen en sus respectivas Condiciones Particulares. El
              diligenciamiento del formulario de registro es obligatoria para
              acceder y disfrutar de los servicios ofrecidos en el sitio web y/o
              APP. Así, para boletines informativos deberá facilitar su
              dirección de correo electrónico y para el uso de determinados
              servicios bajo registro deberá inscribirse como usuario registrado
              de la red de HOME PARTNER pudiéndose identificar siempre con el
              mismo usuario y contraseña, aprovechando con ello todas las
              posibilidades. El no facilitar los datos personales solicitados o
              el no aceptar la política de protección de datos supone la
              imposibilidad de suscribirse, registrarse o participar en
              cualquiera de las promociones en las que se soliciten datos
              carácter personal.
              <br />
              5. Veracidad y actualización de los datos
              <br />
              El usuario registrado (cliente o profesional) o el suscriptor de
              boletines informativos son los únicos responsables de la veracidad
              y corrección de los datos incluidos. HOME PARTNER se exonera de
              cualquier responsabilidad al respecto.
              <br />
              6. Correcta utilización de los sitios web, aplicaciones
              <br />
              Todo usuario que acceda a los sitios web, app y demás productos de
              HOME PARTNER, se compromete a la correcta utilización de cuantos
              servicios se encuentran en el mismo, de acuerdo a lo establecido
              en la legislación vigente que le fuera aplicable y a lo contenido
              en la Condiciones Generales y, en su caso, en las Condiciones
              Particulares publicadas en el sitio web en cada momento para cada
              producto o servicio, absteniéndose de realizar cualquier acto que
              pudiera ir en contra de los intereses de terceros y de la
              estructura y funcionamiento del sitio web de HOME PARTNER.
              <br />
              7. Mayoría de edad
              <br />
              Al aceptar estas Condiciones Generales el usuario manifiesta que
              es mayor de edad, que tiene plena capacidad de obrar, que ha leído
              y comprendido las Condiciones de uso, términos y condiciones que a
              continuación se desarrollan y demás términos legales como la
              política de privacidad o la política de cookies.
              <br />
              8. Comunicaciones comerciales
              <br />
              La dirección de correo electrónico y demás datos personales
              facilitados por el usuario a HOME PARTNER podrán ser utilizados
              para el envío de comunicaciones comerciales o promocionales así
              como para actividades propias del objeto social de HOME PARTNER,
              bien sea a través del correo electrónico o de cualquier otro medio
              de comunicación equivalente, otorgando el usuario consentimiento
              expreso para estas acciones.
              <br />
              9. Opciones del usuario
              <br />
              En algunos de nuestros sitios web, app y demás productos pueden
              incorporar la posibilidad de opinar sobre el servicio/producto
              publicitado. HOME PARTNER no se hace responsable de las opiniones
              o comentarios realizadas por el usuario obligándose este último a
              no publicar comentarios o acciones: (i) difamatorios, inexactos,
              falsos, abusivos, injuriosos, obscenos, irreverentes, ofensivos,
              insultantes, tácita o expresamente sexuales, amenazantes,
              acosadores, racistas, sexistas, discriminatorios por cualquier
              motivo, que atenten contra la moral, el orden público, los
              derechos fundamentales, las libertades públicas, el honor, la
              intimidad o la imagen de terceros y, en general, la normativa
              vigente; y/o ilegales o infractores de derechos de terceros; (ii)
              transmisores de correo basura, “SPAM”, mensajes con fines
              comerciales, o mailings masivos no solicitados, falsos, engañosos
              o que promuevan actividades ilegales o conducta grosera,
              amenazante, obscena, difamatoria o injuriosa; (iii) que
              suministren información práctica sobre actividades ilegales; (iv)
              que conlleven cualquier actividad publicitaria o comercial,
              incluso aunque no haya contraprestación monetaria; (v) que
              contengan datos de carácter personal sobre terceras personas que
              requieran el consentimiento del titular de los derechos. El
              usuario responderá frente a HOME PARTNER o frente a cualquier
              tercero, de cualesquiera daños y/o perjuicios que pudieran
              causarse como consecuencia del incumplimiento de dichas
              obligaciones. Los sitios web, app y demás productos pueden
              permitir la emisión y publicación de opiniones, positivas o
              negativas. HOME PARTNER no interviene las opiniones de los
              usuarios. No obstante, no publicará o eliminará las opiniones que
              no cumplan con las condiciones anteriores, no haciéndose
              responsable ni garantizando la veracidad y exactitud de las
              opiniones que puedan exponerse por los usuarios en su web, app y
              demás productos.
              <br />
              Si HOME PARTNER detecta perfiles de usuarios y/o contenidos que no
              van acordes a lo establecido en las condiciones de uso del
              producto o cualquier acto ilegal, HOME PARTNER podrá borrar o
              modificar aquellos contenidos o perfiles en un plazo razonable
              desde que tenga conocimiento de ello.
              <br />
              10. Propiedad intelectual (derechos de autor, propiedad industrial
              y equivalentes)
              <br />
              Toda la información contenida en la página web, app y demás
              productos, incluyendo, sin carácter limitativo, bases de datos,
              diseño gráfico, fotografías, logos, marcas, imágenes, audio,
              video, documentos, código fuente (HTML y Javascript, CSC, PHP),
              constituye una obra cuya propiedad intelectual pertenece a HOME
              PARTNER, o a terceros, sin que pueda entenderse que el uso o
              acceso a los sitios web, app y demás productos y/o a los servicios
              que se ofrecen, atribuya al usuario derecho alguno sobre esta
              información. El usuario se abstendrá de reproducir o copiar,
              distribuir, permitir el acceso del público a través de cualquier
              modalidad de comunicación pública, transformar o modificar los
              servicios, a menos que se cuente con la autorización de HOME
              PARTNER; suprimir, eludir o manipular cualquier dato o signo
              identificativo de los derechos de HOME PARTNER o de sus titulares
              incorporados a los servicios así como los dispositivos técnicos de
              protección, y en particular, utilizar la información de cualquier
              clase obtenida a través del sitio web de HOME PARTNER o de los
              servicios, para remitir comunicaciones con fines de venta directa
              o con cualquier otra clase de finalidad comercial, enviar mensajes
              no solicitados dirigidos a una pluralidad de personas con
              independencia de su finalidad, así como a abstenerse de
              comercializar o divulgar de cualquier modo dicha información;
              obtener cualquier imagen, grabación, software y, en general,
              cualquier clase de material accesible a través de la página web,
              app y demás productos, mediante cualquier medio diferente a los
              que HOME PARTNER ponga a disposición de los usuarios.
              <br />
              <br />
              <strong>
                IV. CONDICIONES PARTICULARES DE HOME PARTNER (RELACIÓN HOME
                PARTNER Y USUARIOS)
              </strong>
              <br />
              Los productos HOME PARTNER se podrán prestar en diferentes medios,
              como plataforma móvil y sitios web. Dentro de las plataformas, los
              usuarios podrán tomar rol de “Profesional” y/o “Cliente” (en
              adelante de forma conjunta “usuarios”). Los clientes demandantes
              de servicios anuncian por medio de HOME PARTNER sus necesidades y
              pueden recibir contactos o cotizaciones pertinentes. Los
              prestadores de servicios (“profesionales”) acceden a las
              peticiones de los clientes y pueden ofertar sus servicios enviando
              mensajes vía chat de HOME PARTNER o enviando cotizaciones para la
              aceptación de los servicios y/o productos.
              <br />
              La responsabilidad de contratación de servicios o términos
              relativos a acuerdos entre cliente y HOME PARTNER, es
              responsabilidad absoluta del cliente.
              <br />
              Para poder utilizar el Servicio es necesario el previo registro
              del Usuario, la aceptación de los presentes Términos de uso y la
              Política de privacidad y de cookies. Para la utilización de la
              plataforma o app, el usuario deberá completar un proceso de
              registro dónde podrá hacer el registro de diferentes formas:
              <br />
              a. Login a través' de facebook: vienen por defecto otorgados los
              campos de:
              <br />
              i. A Email
              <br />
              ii. Nombre y apellido
              <br />
              iii. Dirección
              <br />
              iv. Siendo A obligatorio, y el resto editables
              <br />
              b. Login a través de Google: vienen por defecto otorgados los
              campos de:
              <br />
              i. A Email
              <br />
              ii. Nombre y apellido
              <br />
              iii. Dirección
              <br />
              iv. Siendo A obligatorio, y el resto editables
              <br />
              c. Introduciendo de forma correcta y veraz a través del formulario
              web los siguientes campos:
              <br />
              i. Nombre y apellido
              <br />
              ii. Email
              <br />
              iii. Dirección
              <br />
              <br />
              HOME PARTNER ingresa estos datos a la plataforma para proceder con
              la entrega del servicio.
              <br />
              Para la activación de HOME PARTNER, tan sólo es necesario el
              registro en la plataforma web o app. Para la utilización de la
              plataforma o app, el profesional deberá completar un proceso de
              registro dónde podrá hacer el registro completando un formulario
              con los siguientes campos obligatorios:
              <br />
              a. Tipo de servicio al que aplica (plomería, electricidad,
              pintura, gas, instalaciones)
              <br />
              b. Nombre
              <br />
              c. Apellido
              <br />
              d. Numero de documento identidad
              <br />
              e. Adjuntar foto y/o fotocopia del documento de identidad
              <br />
              f. Fecha de nacimiento
              <br />
              g. Numero de celular
              <br />
              h. Dirección
              <br />
              i. Email
              <br />
              j. Adjuntar hoja de vida
              <br />
              k. Adjuntar certificación laboral
              <br />
              l. Adjuntar certificado de estudios y/o capacitación en el caso
              que la especialidad lo requiera
              <br />
              Los datos de carácter personal que nos proporcione tanto en el
              momento de registrarse como “Cliente” o “Profesional” de HOME
              PARTNER, como los que nos facilite a consecuencia de la
              utilización de nuestra página Web y/o de nuestra app y de la de
              los servicios ofrecidos por éstas, así como los datos a los que
              HOME PARTNER acceda como consecuencia de su navegación,
              solicitudes, publicaciones, conversaciones, búsquedas,
              cotizaciones o valoraciones de otros usuarios y los datos
              identificativos derivados, en su caso, de la vinculación de su
              registro en nuestra Página Web o en nuestra aplicación con su
              cuenta en Facebook o Google (en adelante, todos ellos denominados
              los “Datos de Carácter Personal”), serán recogidos en un fichero
              cuyo responsable es HOME PARTNER.
              <br />
              En este mismo sentido, le informamos que HOME PARTNER obtendrá y
              utilizará los datos de la IP y demás identificadores
              proporcionados por el dispositivo desde el que navegue por nuestra
              Web o por nuestra APP para el correcto funcionamiento de dichas
              herramientas, así como con fines estadísticos sobre el uso de HOME
              PARTNER, quedando dichos datos incorporados en el citado fichero
              titularidad de HOME PARTNER como datos de carácter personal.
              <br />
              Todos los usuarios que cumplan el rol de Clientes, se comprometen
              a solicitar, demandar o publicar servicios reales, verificar la
              veracidad de la información indicada por el profesional antes de
              aceptar cualquier de los servicios ofertados, así como abonar los
              honorarios propuestos por los profesionales y acordados entre los
              usuarios (clientes y profesionales).
              <br />
              Todos los usuarios que cumplan el rol de Profesionales, deberá
              autorizar a HOME PARTNER la consulta de sus datos ante las
              distintas entidades para la verificación de antecedentes penales y
              se comprometen a cumplir con las obligaciones legales para el
              correcto desarrollo de su actividad (realizar aportes a seguridad
              social y ARL según el tipo de servicio que presta), prestar sus
              servicios de manera eficiente, diligente y con estándares de
              calidad, garantizando la correcta prestación de sus servicios, los
              cuales serán ofrecidos de acuerdo a las orientaciones dadas por el
              cliente, ofreciendo las garantías oportunas respecto de los
              servicios prestados.
              <br />
              <br />
              <strong>
                V. CONDICIONES QUE RIGEN EL CONTRATO ENTRE HOME PARTNER Y
                PROFESIONAL (QUIEN TAMBIÉN PODRÁ DENOMINARSE “EL USUARIO”)
              </strong>
              <br />
              El Usuario se obliga con HOME PARTNER a: (i) Entregar y/o
              suministrar de manera exacta y veraz todos los datos e información
              que HOME PARTNER le solicite para la adecuada ejecución del
              Contrato; (ii) Tomar las medidas necesarias para asegurar que la
              información entregada a HOME PARTNER sea verídica; refleje
              adecuadamente la realidad jurídica de su negocio y actividades
              mercantiles y profesionales; no induzca a error a terceros ni a
              los consumidores finales de sus servicios, bienes o productos (en
              adelante los “Consumidores Finales”); cumpla con la normatividad
              vigente que regula su actividad mercantil o profesional, en
              especial, la relativa a la protección de los derechos de los
              consumidores (Estatuto de Protección al Consumidor en adelante el
              “EPC”, si a ello hay lugar) y no vulnere derechos de terceros;
              (iii) Cumplir estrictamente con las normas que sobre incentivos,
              información y publicidad contiene el EPC respecto de la
              información que le entrega y/o suministra a HOME PARTNER para la
              ejecución del Contrato. En este sentido, el Usuario se abstendrá,
              entre otras conductas, de entregar y/o suministrar a HOME PARTNER
              información que pueda calificarse como publicidad engañosa, que no
              corresponda a la realidad o sea insuficiente de manera que induzca
              o pueda inducir al Consumidor Final a engaño, error o confusión
              respecto de los bienes o productos ofrecidos por el Cliente; (iv)
              cumplir con las condiciones objetivas y subjetivas anunciadas en
              la publicidad que contrata a través de HOME PARTNER; (v) Realizar
              las correcciones a que haya lugar y enviar la información
              adicional que HOME PARTNER le solicite en desarrollo del contrato
              dentro de los plazos contenidos en la respectiva solicitud; (vi)
              Pagar el precio de conformidad con los términos y plazos de pago
              pactados; (vii) Garantizar la calidad, idoneidad y seguridad de
              los bienes, productos y servicios que ofrezca a los Consumidores
              Finales a través de HOME PARTNER y (viii) Defender, reparar y
              mantener indemne a HOME PARTNER por reclamaciones judiciales o
              extrajudiciales presentadas por los Consumidores Finales o por
              cualquier tercero, derivadas del uso por parte de HOME PARTNER de
              la información entregada y/o suministrada por el Usuario para la
              ejecución del Contrato, incluyendo pero sin limitarse a
              infracciones de derechos de propiedad intelectual de terceros,
              violaciones al EPC y/o a otras normas de protección al consumidor
              por publicidad engañosa y, en general, por cualquier violación o
              incumplimiento de la normatividad vigente. HOME PARTNER se obliga
              con el Usuario a prestar los Servicios contratados basándose
              exclusivamente en la información entregada y/o suministrada por el
              Usuario y de acuerdo con las condiciones previstas en el Contrato.
              En caso de retardo por parte del Usuario en el pago del precio
              dentro del plazo previsto, se causarán a favor de HOME PARTNER
              intereses moratorios calculados a la tasa máxima permitida por la
              ley vigente al momento del incumplimiento. Parágrafo I.- El
              Usuario renuncia expresamente a todo requerimiento judicial o
              extrajudicial para constituirse en mora en el cumplimiento de las
              obligaciones que se contemplan en este u otro contrato. Parágrafo
              II.- HOME PARTNER podrá abstenerse válidamente de prestar los
              Servicios sin que ello constituya un incumplimiento del Contrato,
              cuando el Usuario se encuentre en mora de pagar a HOME PARTNER
              cualquier obligación o prestación en virtud de este u otro
              Contrato suscrito entre las partes. El contrato, las órdenes de
              servicio y demás anexos prestan mérito ejecutivo para exigir por
              parte de HOME PARTNER el pago de las obligaciones a cargo del
              Usuario. Cualquier solicitud del Usuario posterior a la firma del
              Contrato de cambio en la estructura, información y/o datos
              suministrados por el Usuario para la prestación de los Servicios
              por parte de HOME PARTNER, se sujetará a las limitaciones,
              condiciones y plazos previstos en el Contrato y en los Anexos para
              cada Servicio. HOME PARTNER podrá abstenerse de incluir la
              información del Usuario dentro de anuncios, productos,
              publicaciones u otro material que elabore en desarrollo del
              Contrato sin que ello constituya un incumplimiento de su parte
              cuando a juicio exclusivo de HOME PARTNER, la información
              entregada y/o suministrada por el Usuario: i) no sea veraz, clara,
              exacta y/o pueda ser confusa para el público en general y/o para
              los Consumidores Finales; (ii) implique que la prestación de los
              Servicios atenta contra el orden público, la moral o las buenas
              costumbres; (iii) implique que la prestación de los Servicios
              pueda afectar o violar derechos de terceros y/o de los
              Consumidores Finales, y (iv) pueda violar el EPC o cualquier otra
              norma vigente. La anterior facultad no implica, en ningún caso y
              bajo ninguna circunstancia, que HOME PARTNER asuma obligación
              alguna o sea responsable de controlar el contenido, veracidad,
              exactitud, claridad y/o correspondencia con la realidad de la
              información entregada y/o suministrada por el Usuario para la
              prestación de los Servicios. El Usuario garantiza y declara a la
              fecha de este Contrato y durante toda su vigencia que: (i) toda la
              información que entrega y/o suministra a HOME PARTNER para la
              prestación de los Servicios, escrita y verbal, es verídica,
              exacta, cierta, fidedigna, suficiente, completa y refleja
              adecuadamente la realidad jurídica de su negocio y actividades
              mercantiles y profesionales, no induce a error a terceros, cumple
              con la normatividad vigente que regula su actividad mercantil o
              profesional, no viola el EPC ni ninguna otra norma vigente de
              protección al consumidor y/o ninguna normatividad que le resulte
              aplicable, y no vulnera derechos de terceros ni de los
              Consumidores Finales; (ii) quien acepta este Contrato se encuentra
              plenamente facultado para actuar por cuenta y representación del
              Usuario en la calidad que se indica en el Contrato; (iii) tiene
              todas las licencias, permisos, consentimientos, autorizaciones o
              aprobaciones de todas las entidades gubernamentales o regulatorias
              para adelantar sus negocios, prestar los servicios o bienes que
              ofrece al público y/o a Consumidores Finales y desarrollar las
              actividades propias de su objeto social durante el período
              previsto para su existencia; (iv) la realización de las
              operaciones, servicios y/o bienes ofrecidos por el Usuario a
              través de HOME PARTNER no contraviene ni está en conflicto con la
              propiedad intelectual de un tercero; (v) está en cumplimiento con
              todas las leyes que le son aplicables. La existencia y veracidad
              de las anteriores declaraciones constituyen la causa determinante
              que induce a HOME PARTNER a celebrar el presente Contrato por lo
              que su falsedad o incumplimiento se tendrá como un incumplimiento
              del Contrato de parte del cliente. El Contrato está sujeto a las
              siguientes exclusiones y limitaciones de responsabilidad de HOME
              PARTNER: (i) La composición, diagramación, ubicación, tipo de
              letra, tamaño, orden y disposición de la información del Usuario
              dentro de anuncios, productos, publicaciones u otro material que
              elabore HOME PARTNER en desarrollo de los Servicios, serán
              determinados exclusivamente por HOME PARTNER, de acuerdo con su
              criterio y experiencia. En consecuencia, el precio pagado por el
              Usuario no le asegura ninguna ubicación específica en los
              anuncios, productos, publicaciones u otro material que elabore
              HOME PARTNER en desarrollo de los Servicios contratados, salvo que
              expresamente se disponga lo contrario en el anexo correspondiente
              firmado por HOME PARTNER y el cliente; (ii) HOME PARTNER se
              reserva el derecho de modificar unilateralmente las
              características y funcionalidades de los sistemas operativos,
              plataformas, software y/o demás plataformas que se utilicen para
              la prestación de los Servicios; (iii) HOME PARTNER no asume
              responsabilidad alguna por la legalidad, claridad, exactitud,
              correspondencia con la realidad y/o veracidad de la información
              y/o datos suministrados por el Usuario; (iv) HOME PARTNER no asume
              responsabilidad alguna por la prestación de los servicios o la
              calidad de los productos ofrecidos por el Usuario a los
              Consumidores Finales y/o a cualquier tercero y, en consecuencia,
              tampoco asume ningún tipo de responsabilidad por el contenido de
              los anuncios, productos, publicaciones u otro material que elabore
              HOME PARTNER en desarrollo de los Servicios basados en la
              información y los datos entregados y/o suministrados a HOME
              PARTNER por el Usuario; (v) HOME PARTNER no asume responsabilidad
              alguna de ningún tipo por el contenido, veracidad, exactitud,
              claridad y correspondencia con la realidad de la información
              entregada y/o suministrada por el Usuario y por medio de este
              Contrato no adquiere la obligación de controlar ni verificar su
              contenido ni veracidad; (vi) Si la prestación de los Servicios
              involucra el uso de la plataforma tecnológica, la prestación de
              los Servicios dependerá de la disponibilidad del sistema. En
              consecuencia, HOME PARTNER no garantiza la disponibilidad,
              continuidad, precisión y fiabilidad del funcionamiento de los
              Servicios o de dichos sistemas; (vii) HOME PARTNER no garantiza
              que su sitio en internet funcione sin interrupción alguna o que
              esté libre de errores o virus, ni responde en el caso de
              interrupción de sus sitios o por cualquier error que se produzca
              en estos, así como tampoco por la pérdida de datos que se
              produzcan con ocasión o por causa de la actividad, gestión y
              administración del proveedor de acceso a internet u otras
              personas; (viii) HOME PARTNER no responderá por variaciones
              visuales que sufran las imágenes o logotipos que contengan
              información del Usuario, o de los borradores enviados a este, al
              ser publicada o insertada en los anuncios, publicaciones u otros
              materiales que elabore HOME PARTNER en desarrollo del Contrato y
              que sean consecuencia de la adaptación de la respectiva imagen al
              medio impreso o electrónico respectivo; (ix) HOME PARTNER no
              responderá por daños derivados de quejas, reclamos, demandas,
              acciones de cualquier tipo que presenten los Consumidores Finales
              en su contra o en contra del Usuario, incluyendo pero sin
              limitarse a las que se sustentan en vulneración de la buena fe
              contractual, inexactitud en la información o datos del Usuario,
              y/o defectos o mala calidad de sus productos o servicios, así como
              tampoco HOME PARTNER responderá por incumplimientos del Usuario,
              eventos en los cuales el Cliente se obliga a defender y mantener
              indemne a HOME PARTNER. En estos eventos y en los demás que
              razonablemente considere pertinentes, HOME PARTNER podrá suspender
              el acceso del profesional a su plataforma hasta tanto no se
              aclaren adecuadamente los hechos objeto de quejas, denuncias o
              reclamaciones.
              <br />
              HOME PARTNER actúa en forma independiente del Usuario, con
              autonomía técnica y administrativa en la prestación de los
              Servicios. Por lo anterior, no existirá relación laboral o de
              subordinación alguna ni de ningún tipo entre HOME PARTNER y el
              personal del Usuario. La suscripción del Contrato tampoco crea
              relación de intermediación, de agencia, ni una empresa conjunta
              (joint venture), sociedad de hecho, contrato de cuentas en
              participación, ni ninguna otra forma asociativa entre HOME PARTNER
              y el Usuario ni entre estos y los funcionarios o empleados de la
              otra Parte. A su vez, el Usuario actuará frente a los Consumidores
              Finales, como el proveedor, fabricante o expendedor exclusivo y
              directo de los bienes o servicios que ofrezca a los mismos, con
              autonomía técnica y administrativa, de forma independiente de HOME
              PARTNER.
              <br />
              Todo nuevo diseño, clasificación, base de datos, diagramación,
              programa de computador, incluyendo gráficos e imágenes que hayan
              sido creados por HOME PARTNER o que esté autorizado para su uso en
              ejecución del Contrato y que no haya sido entregado y/o
              suministrado directamente por el Usuario, es propiedad de HOME
              PARTNER o de un tercero de quien HOME PARTNER ha obtenido la
              autorización. En tal virtud, dichas creaciones de HOME PARTNER no
              podrán ser utilizadas para fines privados o comerciales, ni
              copiados y/o divulgados por ningún medio por el Cliente o por un
              tercero sin la previa y expresa autorización de HOME PARTNER.
              <br />
              En caso de que los Servicios impliquen el uso de una plataforma
              tecnológica de HOME PARTNER y/o administrada por HOME PARTNER y si
              a juicio de HOME PARTNER, el Usuario está usando los Servicios o
              dicha plataforma con un fin u objeto ilícito o violando el aviso
              legal de la respectiva plataforma, HOME PARTNER podrá suspender en
              forma inmediata o terminar unilateralmente y a su arbitrio el
              contrato, sin que ello dé lugar a reparación o indemnización de
              ningún tipo a favor del Usuario y sin que se afecte el derecho de
              HOME PARTNER de cobrar las obligaciones causadas a la fecha de
              suspensión, las sanciones previstas en el Contrato y de buscar una
              reparación integral de los perjuicios sufridos, si a ello hubiere
              lugar.
              <br />
              En cumplimiento del deber legal que le asiste a HOME PARTNER con
              la cooperación en la prevención y control sobre el lavado de
              activos, financiación del terrorismo y cualquier otro delito de
              similar categoría contemplado en normas nacionales e
              internacionales, el Cliente declara que su patrimonio y las
              fuentes económicas que permiten desarrollar su objeto social no
              son fruto de actividades ilícitas, tales como narcotráfico,
              testaferrato, enriquecimiento ilícito, terrorismo, lavado de
              activos, tráfico de estupefacientes, secuestro y/o trata de
              personas, entre otras, razón por la cual acepta que en el evento
              en que el Usuario o alguno de sus socios o accionistas, empleados,
              administradores y/o representantes legales, hagan parte de la
              lista OFAC (Office of Foreign Assets Control) o de alguna otra de
              igual o similar naturaleza, de carácter nacional o internacional,
              o haya sido sentenciado judicialmente por cualquier delito, será
              responsable por los daños y perjuicios que dicho reporte o
              sentencia le ocasione a HOME PARTNER, sin perjuicio de la
              responsabilidad penal que le asiste. Además, faculta a HOME
              PARTNER para dar por terminado unilateralmente el presente
              Contrato en cualquier momento sin previo aviso y sin lugar al pago
              de ninguna reparación o indemnización.
              <br />
              Cualquier información dato y/o documentación comercial,
              financiera, técnica, contable y demás información entregada y/o
              suministrada por cualquier medio por HOME PARTNER al Usuario en la
              negociación, celebración, ejecución o terminación del Contrato
              tendrá el carácter de confidencial. En consecuencia, el Usuario no
              podrá copiarla, divulgarla a terceros o explotarla con fines
              comerciales o privados. Así mismo, el Usuario deberá utilizar la
              máxima diligencia y la buena fe para preservar y mantener como
              confidencial la información que ha recibido de HOME PARTNER.
              <br />
              La responsabilidad total agregada de HOME PARTNER bajo el presente
              Acuerdo, ya sea de manera contractual, extracontractual o, de
              cualquier forma y bajo cualquier teoría de responsabilidad, se
              limitará al ciento por ciento (100 %) del precio de los servicios
              del contrato sobre el cual se presente la reclamación. En ningún
              caso, HOME PARTNER será responsable por daños imprevisibles, lucro
              cesante, ninguna pérdida de beneficios, pérdida de oportunidades,
              pérdida de ingresos, pérdida de tiempo, pérdida de fondo de
              comercio o menoscabo del prestigio, daños punitivos o cualesquiera
              pérdidas o daños indirectos, derivados o especiales con
              independencia de su causa y de que fueran previsibles o no,
              circunstancia que el Usuario acepta libre y voluntariamente.
              <br />
              HOME PARTNER podrá terminar unilateralmente el presente Contrato
              en cualquier momento, sin previo aviso, sin justificación, sin
              necesidad de declaración judicial y sin que haya lugar a
              indemnización o reparación alguna. En tal circunstancia,
              restituirá el precio en caso de que se haya pagado por parte del
              Usuario, si a ello hay lugar y solo en la proporción que le
              corresponda.
              <br />
              No aplicación del estatuto de protección al consumidor (EPC): El
              EPC no le resulta aplicable ni a este Contrato ni a la relación
              comercial entre las Partes, toda vez que el Usuario contrata los
              Servicios de HOME PARTNER con una finalidad intrínsecamente ligada
              a su actividad económica, esto es, para publicitar sus productos o
              servicios frente a los Consumidores Finales. Lo anterior, sin
              perjuicio de que respecto de la relación comercial que el Usuario
              establezca con los Consumidores Finales se de aplicación al EPC y,
              por ende, el Usuario deba cumplir en su totalidad, con las normas
              contenidas en el mismo.
              <br />
              <br />
              <strong>
                VI. CONDICIONES DE CERTIFICADOS DE SEGURIDAD PARA PROFESIONALES
              </strong>
              <br />
              Los profesionales podrán acceder al beneficio de “Certificado de
              Seguridad” añadiendo una serie de requisitos legales que
              certifiquen y avalen credenciales como profesionales, tales como:
              <br />
              a. Documento de identidad
              <br />
              i. Registro fotográfico del frente del documento de identidad
              <br />
              ii. Registro fotográfico del respaldo del documento de identidad
              <br />
              b. Número de tarjeta profesional o licencia, en el caso que
              aplique en la especialidad en la que el profesional desarrolla su
              actividad.
              <br />
              c. Registro Único Tributario si el profesional es sujeto de
              impuestos por la prestación de sus servicios.
              <br />
              d. Foto de los Certificados de Formación con los que cuenta el
              profesional
              <br />
              e. Foto de las certificaciones laborales con los que cuenta el
              profesional
              <br />
              f. Foto de antecedentes penales (procuraduría, policía,
              contraloría)
              <br />
              En orden de obtener el Certificado de Seguridad, el profesional
              deberá cumplir con todos los requisitos antes mencionados. Siendo
              todos obligatorios, sujetos a revisión por parte de HOME PARTNER,
              quien podrá aceptar o declinar los requisitos entregados por el
              profesional. HOME PARTNER no está en la obligación de otorgar el
              certificado de seguridad a ningún profesional, así mismo podrá
              privar o eliminar dicho Certificado de Seguridad, en cualquier
              momento y sin previo aviso, a cualquier Profesional que incumpla
              con los requisitos o con los términos y condiciones de HOME
              PARTNER.
              <br />
              <br />
              <strong>VII. CAMBIOS EN EL ACUERDO DE SERVICIOS</strong>
              <br />
              HOME PARTNER se reserva el derecho a realizar modificaciones en el
              Acuerdo de Servicios en cualquier momento, por ejemplo, para
              tratar cambios de la ley o normativa o cambios en las
              funcionalidades ofrecidas a través de LA PLATAFORMA. Por tal
              motivo, deberá revisar el Acuerdo de Servicios periódicamente para
              comprobar dichas modificaciones. Si usted no está de acuerdo con
              el Acuerdo de Servicios modificado usted deberá dejar de utilizar
              LA PLATAFORMA. Su uso continuado de LA PLATAFORMA después de que
              el Acuerdo de Servicios modificado haya sido publicado constituirá
              su aceptación del Acuerdo de Servicios Modificado.
              <br />
              <br />
              <strong>VIII. R​ESTRICCIONES</strong>
              <br />
              Usted acepta no utilizar los servicios de LA PLATAFORMA para fines
              prohibidos, incluyendo pero no limitado a:
              <br />
              1. En particular el usuario no se hará pasar por otro usuario o
              persona.
              <br />
              2. Al proporcionar los Servicios, LA PLATAFORMA, puede publicar
              anuncios relacionados o no con los contenidos buscados o los
              Servicios, lo cual es expresamente aceptado por usted.
              <br />
              3. Usted se compromete a no causar daño a HOME PARTNER con
              cualquier acción o inacción, incluyendo pero no limitado a la
              interrupción de los Servicios, uso de LA PLATAFORMA para prácticas
              maliciosas, causando gastos excesivos o pérdida de recursos.
              <br />
              4. Usted se obliga a no distribuir ninguna parte de LA PLATAFORMA
              o de los Servicios, incluyendo a título enunciativo, que no
              limitativo, el Contenido, a través de ningún medio sin la previa
              autorización por escrito de HOME PARTNERsalvo en caso de que ponga
              a su disposición los medios para dicha distribución a través de
              una funcionalidad ofrecida por LA PLATAFORMA.
              <br />
              5. HOME PARTNER innova de forma constante con el fin de ofrecer a
              sus usuarios la mejor experiencia posible. Usted reconoce y acepta
              que la forma y naturaleza de los Servicios prestados por LA
              PLATAFORMA podrá variar en cualquier momento sin necesidad de
              preaviso.
              <br />
              6.Como parte de este proceso de innovación constante, Ud. reconoce
              y acepta que LA PLATAFORMA podrá necesitar interrumpir (de forma
              permanente o temporal) la prestación de los Servicios (o de
              cualquier función de éstos) a usted o a los usuarios en general,
              como consecuencia de requisitos operacionales tales como aquellos
              relacionados con la seguridad de los Servicios, su seguridad como
              usuario, o a necesidades de mantenimiento de los Servicios.
              <br />
              7. Usted no deberá copiar, reproducir, distribuir, transmitir,
              emitir al público, exponer, vender, licenciar, o de cualquier otra
              forma explotar cualquier Contenido para cualquier finalidad sin el
              consentimiento previo y por escrito de HOME PARTNER o los
              licenciantes respectivos del Contenido.
              <br />
              8. Usted reconoce ser el único responsable (sin que HOME PARTNER)
              asuma responsabilidad alguna frente a usted o a cualquier tercero
              para) del incumplimiento de sus obligaciones previstas en el
              Acuerdo de Servicios y de las consecuencias (incluyendo
              cualesquiera pérdidas o daños en que pueda incurrir) derivadas de
              dicho incumplimiento.
              <br />
              9. En su uso de los Servicios, usted cumplirá con todas las leyes
              aplicables.
              <br />
              10. Para usar los Servicios, el Usuario debe registrarse, crear un
              nombre de usuario y contraseña y activar una cuenta. Para este
              fin, el Usuario debe proporcionar una dirección de correo
              electrónico válida donde el Usuario recibirá notificaciones
              relacionadas con los Servicios. Si el Usuario es una entidad legal
              o empresa, su nombre de usuario debe ser su nombre completo o
              nombre corporativo y la persona que se registra en nombre de la
              entidad legal o empresa declara que está debidamente autorizado
              para vincular dicha entidad legal o empresa y que tal entidad
              legal o empresa estará obligado a este Acuerdo de Servicios.
              <br />
              11.El Usuario acepta proporcionar la información obligatoria
              requerida para el registro y también reconoce que dicha
              información es verdadera, completa y actualizada. El usuario es el
              único responsable de mantener dicha información actualizada. Si el
              Usuario proporciona información falsa, desactualizada o
              incompleta, o si HOME PARTNER tiene motivos para sospechar de
              ella, se reserva el derecho de suspender o cancelar la cuenta del
              Usuario.
              <br />
              12. El usuario debe proteger y mantener la confidencialidad de la
              contraseña de la cuenta y no debe divulgarla a terceros. El
              Usuario no debe permitir que otros Usuarios accedan a los
              Servicios a través de la cuenta del Usuario ni utilizar la cuenta
              de otro Usuario para acceder a los Servicios.
              <br />
              13. El usuario es responsable de todas las operaciones llevadas a
              cabo a través de la cuenta del usuario a través de cualquier
              dispositivo. Si el Usuario sospecha que otro usuario está
              utilizando su cuenta, debe informar inmediatamente a HOME PARTNER
              por cualquiera de los medios de comunicación definidos por esta.
            </p>
          </article>
        </div>
      </div>
    );
  }
}

export default Term;
