const initialState = {
  userData: {},
  userServices: [],
  loggedIn: false,
  addresses: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        loggedIn: true
      };
    case "LOGOUT":
      localStorage.removeItem("key");
      return {
        ...state,
        loggedIn: false,
        userData: {}
      };
    case "LOAD_USER":
      return {
        ...state,
        userData: action.payload,
        userServices: action.services
      };
    case "SET_ADDRESSES":
      return {
        ...state,
        addresses: action.payload
      };
    case "DELETE_ADDRESS":
      return {
        ...state,
        addresses: []
      };
    default:
      break;
  }
  return state;
};

export default reducer;
