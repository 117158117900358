const initialState = {
  redirect: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_REDIRECT":
      return { ...state, redirect: action.payload };
    case "CLEAR_REDIRECT":
      return { ...state, redirect: null };
    default:
      return state;
  }
};

export default reducer;
