import React, { Component } from "react";
import "@ionic/react/css/core.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import Auth from "./containers/Auth";
import { connect } from "react-redux";
import Swal from "sweetalert2-react";
import { loadUserData } from "./functions/AuthFunctions";
import { getSystemCore } from "./functions/InformationFunctions";
import store from "./store";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Menu from "./components/Navbar/Menu";
import Pusher from "./services/pusher/Pusher";

import {
  IonApp,
  IonPage,
  IonContent,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import { saveCart, saveProductCart } from "./store/actions/cartActions";

const { logPusher, setPusher } = Pusher;
class App extends Component {
  state = {
    loggedIn: false,
    userData: {},
  };

  componentDidMount() {
    getSystemCore();
    if (localStorage.getItem("key") !== null) {
      if (this.state.loggedIn !== true) {
        loadUserData().then((data) => {
          logPusher();
          setPusher("channel_" + this.props.st_userData.id);
        });
      }
    }

    // Load cart from the local storage
    try {
      if (localStorage.getItem("cart") !== null) {
        store.dispatch(saveCart(JSON.parse(localStorage.getItem("cart"))));
      }
    } catch (error) {
      localStorage.removeItem("cart");
    }
    try {
      if (localStorage.getItem("product-cart") !== null) {
        store.dispatch(
          saveProductCart(JSON.parse(localStorage.getItem("product-cart")))
        );
      }
    } catch (error) {
      localStorage.removeItem("product-cart");
    }
  }
  render() {
    return (
      <div className="App">
        <IonApp>
          <Router>
            <Menu />
            <IonPage>
              <IonContent>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <a
                    href="https://web.whatsapp.com/send?phone=573217750455"
                    className="float-whapp hidden-xs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp my-float"></i>
                  </a>
                  {this.props.st_redirect && (
                    <Redirect to={this.props.st_redirect} />
                  )}
                  <Switch>
                    <Route exact path="/registrar-tecnico">
                      <Auth />
                    </Route>
                    <Route exact path="/">
                      <Dashboard></Dashboard>
                    </Route>
                    {!this.props.st_loggedIn ? (
                      <Route path="/login">
                        <Auth />
                      </Route>
                    ) : (
                      <Redirect from="/login" to="/" />
                    )}
                    {!this.props.st_loggedIn ? (
                      <Route path="/register">
                        <Auth />
                      </Route>
                    ) : (
                      <Redirect from="/register" to="/" />
                    )}
                    {!this.props.st_loggedIn ? (
                      <Route path="/forget">
                        <Auth />
                      </Route>
                    ) : (
                      <Redirect from="/forget" to="/" />
                    )}
                    {localStorage.getItem("key") === null ? (
                      <Route path="/confirmar-compra">
                        <Redirect
                          to={{ pathname: "/login", state: { cart: true } }}
                        ></Redirect>
                      </Route>
                    ) : null}
                    <Dashboard></Dashboard>
                  </Switch>
                  {this.props.st_currentServices.length +
                    this.props.st_currentProducts.length >
                    0 &&
                  !this.props.st_cartPage &&
                  !this.props.st_loginPage ? (
                    <div>
                      <Link
                        to="/confirmar-compra"
                        onClick={this.props.linkedCart}
                        className="btn btn-rojo verde hidden-xs confirmar-btn"
                        style={{
                          position: "fixed",
                          zIndex: "4",
                          bottom: "0px",
                          width: "100vw",
                          borderRadius: "0px",
                          left: "0px",
                        }}
                      >
                        Continuar con la reserva (Recuerda desde el segundo
                        servicio tiene el 25% OFF*){" "}
                        <i className="fa fa-arrow-right"></i>
                      </Link>
                      <Link
                        to="/confirmar-compra"
                        onClick={this.props.linkedCart}
                        className="visible-xs "
                        style={{
                          position: "fixed",
                          top: "80vh",
                          right: "0vw",
                        }}
                      >
                        <IonFab
                          vertical="bottom"
                          edge="false"
                          horizontal="end"
                          slot="fixed"
                        >
                          <IonFabButton>
                            <i className="fa fa-shopping-cart fa-2x"></i>
                            {this.props.st_currentServices.length +
                              this.props.st_currentProducts.length >
                              0 && (
                              <span
                                className="badge badge-warning"
                                id="lblCartCount"
                                style={{ background: "red" }}
                              >
                                {" "}
                                {this.props.st_currentServices.length +
                                  this.props.st_currentProducts.length}{" "}
                              </span>
                            )}
                          </IonFabButton>
                        </IonFab>
                      </Link>
                    </div>
                  ) : null}

                  <Swal
                    show={this.props.st_loading}
                    title="Cargando"
                    text="Comprobando los datos de ingreso."
                    icon="info"
                    allowOutsideClick="false"
                    allowEscapeKey="false"
                    allowEnterKey="false"
                    customClass="modal-un"
                    showConfirmButton="false"
                  ></Swal>
                </MuiPickersUtilsProvider>
              </IonContent>
            </IonPage>
          </Router>
        </IonApp>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    st_loggedIn: state.user.loggedIn,
    st_loading: state.information.loading,
    st_userData: state.user.userData,
    st_currentServices: state.cart.currentServices,
    st_cartPage: state.information.cartPage,
    st_loginPage: state.information.loginPage,
    st_name: state.templates.name,
    st_currentProducts: state.cart.currentProducts,
    st_redirect: state.system.redirect,
  };
};
const setStateToProps = (dispatch) => {
  return {
    setCartRedirect: () => {
      dispatch({ type: "SET_CART_REDIRECT" });
    },
  };
};
export default connect(mapStateToProps, setStateToProps)(App);
