const model = {
  empty: true,
  urgent: true,
  sendable: false,
  error: false,
  errorMsg: "",
  schedule: new Date(),
  addressSelected: "",
  total: "",
  forma: "Efectivo",
  sent: false,
  direction: false,
  addressName: "",
  addressDescription: "",
  addressAddress: "",
  discount: false,
  popUp: false,
  hour: "",
  aditional_notes: "",
  cellphone: "",
  servicesRequested: [],
  payu: false,
  creditCard: {
    name: "",
    number: "",
    month: "01",
    year: "2020",
    installments: "1",
    ccv: "",
  },
};

export default model;
