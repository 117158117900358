import axios from "axios";
import store from "../store";
import { serverlink } from "../enviroment";
import { clearRequest } from "../store/actions/informationActions";
import { deleteAddress as deleteAddressAction } from "../store/actions/userActions";

export const sendRequest = (data) => {
  return axios
    .post(serverlink + "/api/sendRequest", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
      crossDomain: true,
    })
    .then((response) => {
      return {
        success: true,
        information: response,
      };
    })
    .catch((err) => {
      return { success: false, errorMsg: err.data.message };
    });
};

export const sendAddress = (data) => {
  return axios
    .post(serverlink + "/api/sendAddress", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return {
        success: true,
        information: response,
      };
    })
    .catch((err) => {
      return { success: false };
    });
};

export const sendRate = (data) => {
  return axios
    .post(serverlink + "/api/sendRate", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return {
        success: true,
        information: response,
      };
    })
    .catch((err) => {
      return { success: false };
    });
};

export const cancelRequest = (data) => {
  return axios
    .post(serverlink + "/api/cancelRequest", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      store.dispatch(clearRequest());
      return {
        success: true,
        information: response,
      };
    })
    .catch((err) => {
      return { success: false, errorMsg: err.data.message };
    });
};

export const deleteAddress = (data) => {
  return axios
    .post(serverlink + "/api/deleteAddress", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      store.dispatch(deleteAddressAction(data.address_id));
      return {
        success: true,
      };
    })
    .catch((err) => {
      return { success: false };
    });
};

// Method to send suggestions

export const sendSuggestion = (data) => {
  return axios
    .post(serverlink + "/api/admin/suggestions", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      return { success: false, errorMsg: err.data.message };
    });
};

export const sendRegisterTech = (data) => {
  return axios
    .post(serverlink + "/api/register-tech", data, {
      headers: {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      return { success: false, errMsg: "Hubo un error en el envio" };
    });
};

export const sendMessage = (data) => {
  return axios
    .post(serverlink + "/api/admin/messages", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      return { success: false, errMsg: "Hubo un error en el envio" };
    });
};

export const sendImageMessage = (data) => {
  return axios
    .post(serverlink + "/api/messagesImage", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then(() => {
      return { success: true };
    })
    .catch((err) => {
      return { success: false, errMsg: "Hubo un error en el envio" };
    });
};
