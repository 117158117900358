import React, { Component } from "react";
import store from "../../store";
import { serverlink } from "./../../enviroment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { deleteOneOfCart, addToCart } from "../../store/actions/cartActions";
import { sendEvent } from "../../functions/GAFunctions";

class ServiceGrid extends Component {
  constructor() {
    super();
    this.state = {
      quantity: "0",
      triggerPopup: false,
    };
    this.onMinus = this.onMinus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  onChange(e) {
    this.setState({ quantity: e.target.value });
  }
  onAdd() {
    sendEvent("add_to_cart_servicio");
    if (this.state.quantity === 10) {
      alert("No puedes agregar más de 10 servicios de la misma cantidad");
    } else {
      this.setState({ quantity: parseInt(this.state.quantity) + 1 });
      store.dispatch(
        addToCart({
          id: this.props.id,
          name: this.props.name,
          base_cost: this.props.base_cost,
          description: this.props.description,
          quantity: 1,
          category: this.props.category,
          subcategory: this.props.subcategory,
          includes: this.props.includes,
          not_include: this.props.not_include,
        })
      );
    }
  }

  onMinus() {
    if (this.state.quantity == 0) {
    } else {
      this.setState({ quantity: parseInt(this.state.quantity) - 1 });
      store.dispatch(deleteOneOfCart(this.props.id));
    }
  }
  componentWillMount() {
    this.props.st_currentServices.map((x) => {
      if (x.id === this.props.id) {
        this.setState({ quantity: x.quantity });
      }
    });
  }
  render() {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    return (
      <>
        {this.props.counter % 4 === 0 ? (
          <div className="clearfix hidden-xs"></div>
        ) : null}
        {this.props.counter % 2 === 0 ? (
          <div className="clearfix visible-xs"></div>
        ) : null}
        <div className="col-md-3 mb-20 col-xs-6 ">
          <div
            className="sh-l padeado20 pb-20 box-service-grid"
            style={{ minHeight: "320px" }}
          >
            <img
              src={this.props.image}
              className="img-responsive"
              style={{ borderBottom: "solid 4px #f2bb2f" }}
              alt=""
            />
            <p className="mt-10 fs-16 fr-1212 mhr-30 f600">{this.props.name}</p>
            <p className="text-right fs-16 fr-14" style={{ color: "#777" }}>
              <small className="fr-16">
                {this.state.quantity == 0
                  ? formatter.format(this.props.base_cost)
                  : formatter.format(
                      this.props.base_cost * this.state.quantity
                    )}
              </small>
            </p>
            <div className="fs-12 hidden-xs">
              {ReactHtmlParser(this.props.description)}
              <br />
            </div>
            <div className="center-responsive fr-12">
              Cantidad
              <div className="adition-box mtr-10">
                <button
                  className="btn btn-negro"
                  onClick={this.onMinus}
                  style={{
                    minWidth: "28px",
                    padding: "0px 0px",
                    borderRadius: "0px",
                    height: "30px",
                    marginTop: "-3px",
                  }}
                >
                  -
                </button>
                <input
                  value={this.state.quantity}
                  onChange={this.onChange}
                  type="number"
                  style={{
                    width: "40px",
                    height: "30px",
                    textAlign: "center",
                  }}
                />
                <button
                  className="btn btn-negro"
                  onClick={this.onAdd}
                  style={{
                    minWidth: "28px",
                    padding: "0px 0px",
                    borderRadius: "0px",
                    height: "30px",
                    marginTop: "-3px",
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="mt-20 mb-10 text-right center-responsive">
              {this.state.quantity > 0 ? (
                <button
                  onClick={this.props.onContinue}
                  className="btn btn-negro"
                  style={{ fontSize: "12px" }}
                >
                  Continuar
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_currentServices: state.cart.currentServices,
    st_notSame: state.cart.notSame,
  };
};

export default connect(mapStateToProps)(ServiceGrid);
