export function deleteOneOfCart(item) {
  return {
    type: "DELETE_ONE_OF_CART",
    payload: {
      id: item,
    },
  };
}

export function deleteOfCart(item) {
  return { type: "DELETE_OF_CART", payload: { id: item } };
}

export function setService(item) {
  return { type: "SET_SERVICE", payload: item };
}

export function deleteCart(item) {
  return { type: "DELETE_CART", payload: item };
}

export function inCart() {
  return { type: "CART_PAGE" };
}

export function offCart() {
  return { type: "CART_PAGE_OFF" };
}

export function saveCart(item) {
  return {
    type: "SAVE_CART",
    payload: item,
  };
}

export function addToCart(item) {
  return {
    type: "ADD_TO_CART",
    payload: item,
  };
}

export function addProductToCart(item) {
  return {
    type: "ADD_PRODUCT_TO_CART",
    payload: item,
  };
}

export function saveProductCart(item) {
  return {
    type: "SAVE_PRODUCT_CART",
    payload: item,
  };
}

export function deleteProductOfCart(item) {
  return { type: "DELETE_PRODUCT_OF_CART", payload: { id: item } };
}

export function deleteProductCart(item) {
  return { type: "DELETE_PRODUCT_CART", payload: item };
}

export function deleteOneProductOfCart(item) {
  return { type: "DELETE_ONE_PRODUCT_OF_CART", payload: item };
}
