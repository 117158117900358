import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { sendRequest } from "./../../functions/SendingFunctions";
import { sendAddress } from "./../../functions/SendingFunctions";
import { deleteAddress } from "./../../functions/SendingFunctions";
import Swal from "sweetalert2-react";
import { loadUserData } from "../../functions/AuthFunctions";
import PopupIncludes from "../../components/Popups/includes";
import { DatePicker } from "@material-ui/pickers";
import amber from "@material-ui/core/colors/amber";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import "moment/locale/es";
import PopupAddress from "../../components/Address/Popup";
import ServicesList from "../../components/Cart/ItemList";
import EmptyCart from "../../components/Cart/Empty";
import ListAddresses from "../../components/Address/List";
import Credit from "./Credit";
import {
  deleteOfCart,
  deleteProductCart,
  setService,
  deleteCart,
  inCart,
  offCart,
  deleteProductOfCart,
} from "../../store/actions/cartActions";
import {
  offLink,
  loadingOn,
  loadingOff,
} from "../../store/actions/triggerActions";
import model from "./model";
import { sendEvent } from "../../functions/GAFunctions";
import CryptoJS from "crypto-js";

moment.locale("es");

class Cart extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = model;
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAddress = this.onAddress.bind(this);
    this.onForma = this.onForma.bind(this);
    this.onSendAddress = this.onSendAddress.bind(this);
    this.onDate = this.onDate.bind(this);
    this.onDeleteAddress = this.onDeleteAddress.bind(this);
    this.onEditCredit = this.onEditCredit.bind(this);
    this.session_id = document.cookie;
    this.date = new Date();
    this.sessionId = CryptoJS.MD5(this.session_id + this.date.getTime());
  }

  onEditCredit(name, value) {
    this.setState({ creditCard: { ...this.state.creditCard, [name]: value } });
  }
  onSubmit(e) {
    e.preventDefault();
    sendEvent("Finalizar_compra");
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss");
    let notes = "Programado para en menos de 90 minutos | " + this.state.forma;
    if (!this.state.urgent) {
      datetime =
        moment(this.state.schedule).format("YYYY-MM-DD").toString() +
        " " +
        this.state.hour;
      notes = "Programado para " + datetime + " | " + this.state.forma;
    }
    this.props.loadingOn();
    if (this.myRef.current.checked) {
      if (this.state.schedule.length > 0) {
        this.props.loadingOFF();
        return this.setState({
          error: true,
          errorMsg: "Escriba una fecha valida",
        });
      }
    }
    if (this.state.addressSelected.length < 1) {
      this.props.loadingOFF();
      return this.setState({
        error: true,
        errorMsg: "No hay una dirección escogida",
      });
    }
    let content_service = "SERVICIOS: \n";
    if (this.props.st_cart.length > 0) {
      this.props.st_cart.map(
        (item) =>
          (content_service =
            content_service +
            " " +
            item.name +
            " x " +
            item.quantity +
            " - " +
            item.base_cost * item.quantity +
            " \n")
      );
    } else {
      content_service = "";
    }

    let content_product = "\n PRODUCTOS: \n";
    if (this.props.st_products.length > 0) {
      this.props.st_products.map(
        (item) =>
          (content_product =
            content_product +
            " " +
            item.name +
            " x " +
            item.quantity +
            " - " +
            item.price_base * item.quantity +
            " \n")
      );
    } else {
      content_product = "";
    }

    const request = {
      datetime: datetime,
      notes: notes,
      address_id: this.state.addressSelected,
      final_price: this.state.total,
      content_service: content_service + content_product,
      aditional_notes: this.state.aditional_notes,
      cellphone: this.state.cellphone,
      services: this.props.st_cart,
      products: this.props.st_products,
      payu: this.state.payu,
      creditCard: this.state.creditCard,
      session_id: "" + this.sessionId,
    };
    sendRequest(request)
      .then((res) => {
        this.props.loadingOFF();
        if (res.success) {
          if (res.information.data.success !== "false") {
            this.props.deleteCart();
            this.props.deleteProductCart();
            this.props.setService(res.information.data.success);
            this.setState({ sent: true });
          } else {
            this.setState({
              error: true,
              errorMsg: res.information.data.message,
            });
          }
        } else {
          this.setState({
            error: true,
            errorMsg: "Hubo un problema de conexión, intenta más tarde.",
          });
        }
        return;
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
    return;
  }
  onDate(date) {
    this.setState({ schedule: date });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onAddress(e) {
    if (e.target.checked) {
      this.setState({ addressSelected: e.target.value });
    }
  }

  onDeleteAddress(identificador) {
    let data = {
      address_id: identificador,
    };
    deleteAddress(data).then((res) => {
      if (res.success) {
        loadUserData();
        this.setState({ direction: false });
      }
    });
  }

  onForma(e) {
    if (e.target.checked) {
      if (e.target.value == "Tarjeta") {
        this.setState({ forma: e.target.value, payu: true });
        return;
      }
      this.setState({ forma: e.target.value, payu: false });
    }
  }

  onSendAddress(childState) {
    let data = {
      name: childState.addressName,
      description: childState.addressDescription,
      address: childState.addressAddress,
      city_id: childState.city,
    };
    sendAddress(data).then((res) => {
      if (res.success) {
        loadUserData();
        this.setState({ direction: false });
      }
    });
  }

  componentWillMount() {
    if (!this.props.st_cartPage) {
      this.props.inCart();
    }
  }

  componentWillUnmount() {
    if (this.props.st_cartPage) {
      this.props.offCart();
    }
  }

  componentDidMount() {
    if (this.props.st_linkedCart) {
      this.props.offLink();
    }
    if (localStorage.getItem("cart") !== null) {
      this.setState({ popUp: true });
      this.setState({ empty: false });
    }

    if (localStorage.getItem("product-cart") !== null) {
      this.setState({ empty: false });
    }
  }
  componentDidUpdate(prevState) {
    if (
      this.props.st_cart.length + this.props.st_products.length === 0 &&
      (prevState.st_cart !== this.props.st_cart ||
        prevState.st_products !== this.props.st_products)
    ) {
      this.setState({ empty: true });
    }
  }
  render() {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    const defaultMaterialTheme = createMuiTheme({
      palette: {
        primary: amber,
      },
    });
    let redirect = null;
    if (this.state.sent == true) {
      redirect = <Redirect to="/realizada"></Redirect>;
    }

    if (
      this.props.st_cart.length + this.props.st_products.length > 0 &&
      this.state.sendable === false
    ) {
      this.setState({ sendable: true });
    }

    const handleRadio = () => {
      if (this.myRef.current.checked) {
        this.setState({ urgent: false });
      } else {
        this.setState({ urgent: true });
      }
    };

    let total = 0;
    let max = 0;
    //Total con DCTO
    this.props.st_cart.map((item) => {
      if (item.quantity > 1 && !this.state.discount) {
        this.setState({ discount: true });
      }
      if (max < item.base_cost) {
        max = item.base_cost;
      }
      total = total + item.base_cost * item.quantity;
    });

    if (this.props.st_cart.length > 1 || this.state.discount) {
      total = (total - max) * 0.75 + max;
      if (!this.state.discount) {
        this.setState({ discount: true });
      }
    }

    // Total of products
    let productTotal = 0;
    if (this.props.st_products.length > 0) {
      this.props.st_products.map((item) => {
        productTotal = productTotal + item.price_base * item.quantity;
      });
    }
    if (this.state.total !== total + productTotal) {
      this.setState({ total: total + productTotal });
    }
    return (
      <div className="row mtr-neg" id="main">
        {redirect}
        {this.state.popUp ? (
          <PopupIncludes
            hidePop={() => this.setState({ popUp: false })}
          ></PopupIncludes>
        ) : null}
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            {this.state.direction ? (
              <PopupAddress
                hidePop={() => this.setState({ direction: false })}
                sendAddress={this.onSendAddress}
              ></PopupAddress>
            ) : this.state.empty ? (
              <EmptyCart />
            ) : (
              <div>
                <h3 className="f2 fs-40 mtr-55">
                  <strong
                    className="nml center-responsive bolder fr40"
                    style={{ marginLeft: "20px", fontSize: "40px!important" }}
                  >
                    Checkout
                  </strong>
                </h3>
                <p className="fs-20 center-responsive fr-16 hidden-xs">
                  ¿Para cuándo lo necesitas?
                </p>
                <form onSubmit={this.onSubmit}>
                  <div className="mt-40 mtr-0 servicios">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className=" pb-20 pbr-60"
                          style={{
                            paddingTop: "10px",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="box-cart">
                                    <p className="mt-10 fs-18 fr-16">
                                      <strong className="f600">
                                        Elige la fecha
                                      </strong>
                                    </p>
                                    <div className="form-group">
                                      <label htmlFor="" className="f300">
                                        <input
                                          type="radio"
                                          name="date_type"
                                          defaultChecked
                                          onClick={handleRadio}
                                        />{" "}
                                        En menos de 90 minutos
                                      </label>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="" className="f300">
                                        <input
                                          type="radio"
                                          name="date_type"
                                          ref={this.myRef}
                                          onClick={handleRadio}
                                        />{" "}
                                        Programado
                                      </label>
                                      <br />
                                      {!this.state.urgent ? (
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="f600">
                                              Fecha
                                            </label>
                                            <br />

                                            <ThemeProvider
                                              theme={defaultMaterialTheme}
                                            >
                                              <DatePicker
                                                name="schedule"
                                                autoOk={true}
                                                disabled={this.state.urgent}
                                                inputVariant="outlined"
                                                value={this.state.schedule}
                                                onChange={this.onDate}
                                                minDate={new Date()}
                                                format="YYYY/MM/DD"
                                              />
                                            </ThemeProvider>
                                          </div>
                                          <div className="clearfix"></div>
                                          <div className="col-md-6 mt-5">
                                            <label className="f600">Hora</label>
                                            <select
                                              className="form-control"
                                              name="hour"
                                              onChange={this.onChange}
                                              disabled={this.state.urgent}
                                            >
                                              <option value="08:00:00">
                                                8 am - 9 am
                                              </option>
                                              <option value="09:00:00">
                                                9 am - 10 am
                                              </option>
                                              <option value="10:00:00">
                                                10 am - 11 am
                                              </option>
                                              <option value="11:00:00">
                                                11 am - 12 m
                                              </option>
                                              <option value="12:00:00">
                                                12 m - 1 pm
                                              </option>
                                              <option value="13:00:00">
                                                1 pm - 2 pm
                                              </option>
                                              <option value="14:00:00">
                                                2 pm - 3 pm
                                              </option>
                                              <option value="15:00:00">
                                                3 pm - 4 pm
                                              </option>
                                              <option value="16:00:00">
                                                4 pm - 5 pm
                                              </option>
                                              <option value="17:00:00">
                                                5 pm - 6 pm
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>
                                  <div className="box-cart">
                                    <p className="mt-10 fs-18 fr-16">
                                      <strong className="f600">
                                        Elige la dirección
                                      </strong>
                                    </p>
                                    <div className="form-group">
                                      {this.props.st_addresses.length > 0 ? (
                                        <div>
                                          {this.props.st_addresses.map(
                                            (item) => (
                                              <ListAddresses
                                                key={item.id}
                                                name={item.name}
                                                id={item.id}
                                                address={item.address}
                                                changeAddress={this.onAddress}
                                                description={item.description}
                                                onDelete={() =>
                                                  this.onDeleteAddress(item.id)
                                                }
                                              />
                                            )
                                          )}
                                          <br />
                                          <Link
                                            onClick={() =>
                                              this.setState({ direction: true })
                                            }
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Agregar nueva dirección
                                          </Link>
                                        </div>
                                      ) : (
                                        <Link
                                          onClick={() =>
                                            this.setState({ direction: true })
                                          }
                                          style={{ color: "gray" }}
                                        >
                                          Agregar nueva dirección
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                  <div className="box-cart">
                                    <div className="form-group">
                                      <label className="f600">
                                        Número de contacto
                                      </label>
                                      <input
                                        name="cellphone"
                                        type="number"
                                        className="form-control"
                                        placeholder="Ingresa el número de contacto"
                                        required="required"
                                        value={this.state.cellphone}
                                        onChange={this.onChange}
                                      ></input>
                                    </div>
                                    <div className="form-group fr-16">
                                      <label className="f600">
                                        Notas adicionales
                                      </label>
                                      <textarea
                                        name="aditional_notes"
                                        placeholder="¿Tienes alguna nota para el técnico?"
                                        className="form-control"
                                        value={this.state.aditional_notes}
                                        onChange={this.onChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="box-cart">
                                <p className="mt-10 fs-18 fr-16">
                                  <strong className="f600">Servicios</strong>
                                </p>
                                {this.props.st_cart.map((item) => (
                                  <ServicesList
                                    key={item.id}
                                    name={item.name}
                                    quantity={item.quantity}
                                    base_cost={item.base_cost}
                                    onDeleteCart={() =>
                                      this.props.deleteOfCart(item.id)
                                    }
                                  />
                                ))}

                                {this.state.discount ? (
                                  <p className="fs-12">
                                    <i>
                                      Se ha aplicado un descuento por solicitar
                                      más de un servicio.
                                    </i>
                                  </p>
                                ) : null}
                                <div className="raya"></div>
                                <p className="mt-10 fs-18 fr-16">
                                  <strong className="f600">Productos</strong>
                                </p>
                                {this.props.st_products.map((item) => (
                                  <ServicesList
                                    key={item.id}
                                    name={item.name}
                                    quantity={item.quantity}
                                    base_cost={item.price_base}
                                    onDeleteCart={() =>
                                      this.props.deleteProductOfCart(item.id)
                                    }
                                  />
                                ))}
                                <div className="raya"></div>
                                <p className="mt-10">
                                  <strong
                                    className="f600"
                                    style={{ color: "#e80000" }}
                                  >
                                    Total: {formatter.format(this.state.total)}
                                  </strong>
                                </p>
                                <div className="raya"></div>
                                <p className="mt-10 fs-18 fr-16">
                                  <strong className="f600">
                                    Forma de pago
                                  </strong>
                                </p>
                                <div className="form-group">
                                  <label htmlFor="efectivo" className="f300">
                                    <input
                                      id="efectivo"
                                      type="radio"
                                      name="forma"
                                      value="Efectivo"
                                      defaultChecked
                                      onChange={this.onForma}
                                    />{" "}
                                    Efectivo
                                  </label>{" "}
                                  <br />
                                  <label htmlFor="datafono" className="f300">
                                    <input
                                      id="datafono"
                                      type="radio"
                                      name="forma"
                                      value="Datafono"
                                      onChange={this.onForma}
                                    />{" "}
                                    Datafono
                                  </label>{" "}
                                  <br />
                                  <label htmlFor="tarjeta" className="f300">
                                    <input
                                      id="tarjeta"
                                      type="radio"
                                      name="forma"
                                      value="Tarjeta"
                                      onChange={this.onForma}
                                    />{" "}
                                    Tarjeta de Crédito
                                  </label>{" "}
                                  <br />
                                  {this.state.payu && (
                                    <Credit
                                      credit={this.state.creditCard}
                                      onEditCredit={this.onEditCredit}
                                      sessionId={this.sessionId}
                                    />
                                  )}
                                  <br />
                                  <br />
                                  <label htmlFor="check">
                                    <input
                                      id="check"
                                      type="checkbox"
                                      required="required"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        padding: "10px",
                                        marginRight: "10px",
                                      }}
                                    ></input>
                                    <small className="f300">
                                      Al solicitar el servicio aceptas nuestros{" "}
                                      <Link to="/terminos-y-condiciones">
                                        Terminos y condiciones
                                      </Link>
                                    </small>
                                  </label>
                                  <button
                                    disabled={!this.state.sendable}
                                    className="btn btn-azul mt-20"
                                    type="submit"
                                  >
                                    Solicitar servicio{" "}
                                    <i className="fa fa-arrow-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <Swal
          show={this.state.error}
          title="Error"
          text={this.state.errorMsg}
          icon="error"
          onConfirm={() => this.setState({ error: false, errorMsg: "" })}
        ></Swal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    st_cart: state.cart.currentServices,
    st_products: state.cart.currentProducts,
    st_currentInformation: state.information.currentInformation,
    st_linkedCart: state.information.linkedCart,
    st_addresses: state.user.addresses,
    st_cartPage: state.information.cartPage,
  };
};
const setStateToProps = (dispatch) => {
  return {
    deleteOfCart: (cartSelected) => dispatch(deleteOfCart(cartSelected)),
    offLink: () => dispatch(offLink()),
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
    setService: (payload) => dispatch(setService(payload)),
    deleteCart: (payload) => dispatch(deleteCart(payload)),
    deleteProductCart: (payload) => dispatch(deleteProductCart()),
    deleteProductOfCart: (payload) => dispatch(deleteProductOfCart(payload)),
    inCart: () => dispatch(inCart()),
    offCart: () => dispatch(offCart()),
  };
};

export default connect(mapStateToProps, setStateToProps)(Cart);
