const initialState = {
  name: "TEST REDUCER",
  bannersWeb: [],
  bannersResponsive: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "BANNERS": {
      return {
        ...state,
        bannersWeb: action.payload
      };
    }
    case "MOBILE": {
      return {
        ...state,
        bannersResponsive: action.payload
      };
    }
    default:
      break;
  }
  return state;
};

export default reducer;
