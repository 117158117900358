import React from "react";
import { connect } from "react-redux";
import { serverlink } from "../../enviroment";
import { Link } from "react-router-dom";
import { selectCategory } from "../../store/actions/informationActions";

const GridCategories = ({ item, index, selectCategory }) => {
  return (
    <>
      {index === 3 && <div className="clearfix visible-xs"></div>}
      <div
        className={
          "col-md-3 col-xs-4 pdr-2" + (index === 4 ? " col-md-offset-3" : "")
        }
        style={{ marginTop: "10px" }}
        key={item.id}
      >
        <div
          className="grid-categories-top sh-b"
          data-aos="zoom-in"
          style={{
            backgroundColor: "whitesmoke",
            borderStyle: "solid",
            borderColor: "#" + item.color,
            borderWidth: "2px",
          }}
        >
          <Link
            onClick={() => selectCategory(item.id)}
            to={"/categoria/" + item.id}
          >
            <div className="fs-12 text-center">
              <img
                className="img-subcategories img-responsive"
                src={item.image}
                style={{ margin: "0 auto", maxHeight: "90px" }}
                alt=""
              />
              <br />
              <h3
                className="fs-14"
                style={{ fontWeight: "600", minHeight: "30px" }}
              >
                {item.name}
              </h3>
            </div>
            <p className="fs-12 hidden-xs text-center" style={{}}>
              {item.description}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    st_menuList: state.information.listCategories,
  };
};
const setStateToProps = (dispatch) => {
  return {
    selectCategory: (categorySelected) =>
      dispatch(selectCategory(categorySelected)),
  };
};

export default connect(mapStateToProps, setStateToProps)(GridCategories);
