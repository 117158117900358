import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemCore } from "../../functions/InformationFunctions";
import store from "../../store";
import {
  setProductSubcategoryName,
  setProductCategoryName,
} from "../../store/actions/productsActions";
import ProductsGrid from "../../components/Products/Grid";
import BackMovil from "../../components/Back/BackMovil";

class Products extends Component {
  // Set category name if this doesn't load yet
  componentDidMount() {
    if (this.props.st_productList.length == 0) {
      getSystemCore().then(() => {
        const { categoria } = this.props.match.params;
        const { subcategoria } = this.props.match.params;
        store.dispatch(setProductCategoryName(categoria));
        store.dispatch(setProductSubcategoryName(subcategoria));
      });
    } else {
      const { categoria } = this.props.match.params;
      const { subcategoria } = this.props.match.params;
      store.dispatch(setProductCategoryName(categoria));
      store.dispatch(setProductSubcategoryName(subcategoria));
    }
  }

  render() {
    const { categoria } = this.props.match.params;
    const { subcategoria } = this.props.match.params;

    // map product list
    const productsGrid = this.props.st_productList.map((item, index) => {
      return (
        <ProductsGrid
          key={item.id}
          image={item.picture}
          name={item.name}
          description={item.description}
          id={item.id}
          category={categoria}
          subcategory={subcategoria}
          base_cost={item.price_base}
          counter={index}
        ></ProductsGrid>
      );
    });

    return (
      <>
        <BackMovil />
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40 hidden-xs">
              <strong style={{ marginLeft: "20px" }}>
                {this.props.st_categoryName}
              </strong>
            </h3>
            {/* responsive */}
            <h3 className="f2 fs-22 visible-xs text-center">
              <br />
              <strong>{this.props.st_categoryName}</strong>
            </h3>

            <div className="mt-40 mtr-20 servicios">
              <div className="row">{productsGrid}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_categoryName: state.products.productCategoryName,
    st_productList: state.products.listProducts,
  };
};

export default connect(mapStateToProps)(Products);
