import React, { Component } from "react";
import Navbar from "../../components/Navbar";
import Categories from "../Categories";
import Subcategories from "../Subcategories";
import Services from "../Services";
import Cart from "../Cart";
import Help from "../Help";
import { Switch, Route } from "react-router-dom";
import Accepted from "../Accepted";
import Pendings from "../Pendings";
import TechAccepted from "../TechAccepted";
import Canceled from "../Canceled";
import History from "../History";
import Terms from "../Terms";
import Qualify from "../Qualify";
import BottomPanel from "../BottomPanel";
import Profile from "../Profile";
import { connect } from "react-redux";
import Suggestion from "../Suggestion";
import { offCart } from "../../store/actions/cartActions";
import ProductsCategory from "../Categories/ProductCategories";
import ProductSubcategory from "../Subcategories/ProductSubcategory";
import Products from "../Products";
import ProductView from "../Products/view";
import SocialAuth from "../../services/auth/SocialAuth";
import IndividualRequest from "../Request/RequestPage";

class DashboardComponent extends Component {
  componentWillMount() {
    if (this.props.st_cartPage) {
      this.props.offCart();
    }
  }

  render() {
    return (
      <div id="wrapper">
        <header>
          <Navbar></Navbar>
        </header>
        <div id="page-wrapper">
          <div className="">
            <Switch>
              <Route exact path="/" component={Categories} />
              <Route
                exact
                path="/categoria/:identifier"
                component={Subcategories}
              />
              <Route
                exact
                path="/categoria/:categoria/subcategoria/:subcategoria"
                component={Services}
              />
              <Route exact path="/tienda" component={ProductsCategory} />
              <Route
                exact
                path="/tienda/:identifier"
                component={ProductSubcategory}
              />
              <Route
                exact
                path="/tienda/:categoria/subcategoria/:subcategoria"
                component={Products}
              />
              <Route
                exact
                path="/product/:identifier"
                component={ProductView}
              />
              <Route exact path="/confirmar-compra" component={Cart} />
              <Route exact path="/realizada" component={Accepted} />
              <Route exact path="/pendientes" component={Pendings} />
              <Route exact path="/aceptadas" component={TechAccepted} />
              <Route exact path="/canceladas" component={Canceled} />
              <Route exact path="/servicios" component={Categories} />
              <Route exact path="/historial" component={History} />
              <Route exact path="/calificar/:service" component={Qualify} />
              <Route exact path="/ayuda" component={Help} />
              <Route exact path="/terminos-y-condiciones" component={Terms} />
              <Route exact path="/perfil" component={Profile} />
              <Route exact path="/sugerencias" component={Suggestion} />
              <Route
                path="/solicitud/:request_id"
                component={IndividualRequest}
              />
              <Route path="/facebooklogin/:token" component={SocialAuth} />
            </Switch>
          </div>
        </div>
        <BottomPanel></BottomPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_cartPage: state.information.cartPage,
  };
};
const setStateToProps = (dispatch) => {
  return {
    offCart: () => dispatch(offCart()),
  };
};

export default connect(mapStateToProps, setStateToProps)(DashboardComponent);
