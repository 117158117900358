import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestImg from "./../../img/request.png";
import "react-chat-elements/dist/main.css";
import { MessageBox, Input, Button } from "react-chat-elements";
import { getRequest } from "../../functions/InformationFunctions";
import { Redirect, useParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import { setMessages } from "../../store/actions/messagesActions";
import {
  formatter,
  triggerSendMessage,
  triggerFileUpload,
  submitImage,
} from "./RequestFunctions";

const IndividualRequest = () => {
  let { request_id } = useParams();
  const st_user_id = useSelector((state) => state.user.userData);
  const st_messages = useSelector((state) => state.messages.messages);
  const dispatch = useDispatch();
  const messageRef = React.createRef();
  const fileRef = React.createRef();
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [item, setItem] = useState({
    content_service: null,
    datetime: null,
    address: null,
    notes: null,
    final_price: null,
    messages: [],
  });

  useEffect(() => {
    if (item.content_service === null) {
      getRequest(request_id).then((data) => {
        if (data.data?.success) {
          setItem(data.data.data);
          dispatch(setMessages(data.data.data.messages));
        } else {
          setRedirect(true);
        }
      });
    }
  });

  return (
    <div className="row" id="main">
      {redirect && <Redirect to={"/"} />}
      <div className="col-sm-12 col-md-12" id="content">
        <div className="padeado">
          <div className="mt-40 servicios">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="sh-l padeado20 pb-20"
                  style={{ backgroundColor: "#f7f7f7", paddingTop: "10px" }}
                >
                  <div className="row">
                    <div className="col-xs-2">
                      <img src={requestImg} className="img-responsive" alt="" />
                    </div>
                    <div className="col-md-8">
                      <strong className="fs-22 f600">
                        Servicio solicitado
                      </strong>{" "}
                      <br />
                      <strong className="f600">Descripción</strong> <br />
                      {item.content_service} <br />
                      <strong className="f600">Fecha: </strong>
                      {item.datetime} <br />
                      <strong className="f600">Dirección: </strong>
                      {item.address?.address +
                        " " +
                        item.address?.description}{" "}
                      <br />
                      <strong className="f600">
                        Notas para el técnico:{" "}
                      </strong>{" "}
                      {item.aditional_notes} <br />
                      <strong className="f600">{item.notes}</strong>
                    </div>
                    <div className="col-md-2 text-right">
                      <p
                        className="text-right fs-22"
                        style={{ color: "#f2bb2f" }}
                      >
                        <small>{formatter.format(item.final_price)}</small>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="sh-l padeado20 pb-20"
                  style={{ backgroundColor: "#f7f7f7", paddingTop: "10px" }}
                >
                  <div className="row">
                    <div className="col-xs-2">
                      <i className="fa fa-comments fa-4x"></i>
                    </div>
                    <div className="col-md-10">
                      <strong className="fs-22 f600">Chat</strong> <br />
                      <strong className="f600"></strong> <br />
                      Comunicate con el técnico que tomará el servicio <br />
                      <ScrollToBottom
                        className="mt-10 chat_box"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                      >
                        {st_messages.map((message) => {
                          return (
                            <>
                              {message.isFile ? (
                                <a
                                  href={message.fileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  <MessageBox
                                    position={message.side}
                                    title={message.author}
                                    date={new Date(message.created_at)}
                                    type={"photo"}
                                    data={{
                                      uri: message.fileLink,
                                      width: "100px",
                                      height: "100px",
                                    }}
                                    avatar={message.avatar}
                                  />
                                </a>
                              ) : (
                                <MessageBox
                                  position={message.side}
                                  title={message.author}
                                  date={new Date(message.created_at)}
                                  type={"text"}
                                  text={message.message}
                                  avatar={message.avatar}
                                />
                              )}
                            </>
                          );
                        })}
                      </ScrollToBottom>
                      <Input
                        ref={messageRef}
                        placeholder="Escribe aquí..."
                        multiline={true}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        rightButtons={
                          <Button
                            color="white"
                            backgroundColor="black"
                            text="Enviar"
                            onClick={() =>
                              triggerSendMessage(
                                {
                                  user_id: st_user_id.id,
                                  request_id: request_id,
                                  message: message,
                                },
                                messageRef
                              )
                            }
                          />
                        }
                        leftButtons={
                          <Button
                            color="white"
                            backgroundColor="green"
                            text={<i className="fa fa-paperclip"></i>}
                            onClick={() => triggerFileUpload(fileRef)}
                          />
                        }
                      />
                      <input
                        ref={fileRef}
                        type="file"
                        style={{ visibility: "hidden" }}
                        onChange={() =>
                          submitImage({
                            fileRef: fileRef,
                            request_id: request_id,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualRequest;
