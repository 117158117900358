import React, { Component } from "react";
import imgFondo from "./../../img/bg1.jpg";
import imgLogo from "./../../img/logohp.png";
import { Switch, Route, Link } from "react-router-dom";
import Login from "./../../components/Auth/Login";
import Register from "./../../components/Auth/Register";
import Forget from "./../../components/Auth/Forget";
import RegisterTech from "../../containers/Auth/RegisterTech";

class Auth extends Component {
  render() {
    return (
      <section
        style={{
          background: `url(${imgFondo})`,
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        <Link to="/">
          <img
            src={imgLogo}
            style={{
              maxWidth: "250px",
              position: "absolute",
            }}
            alt=""
          />
        </Link>
        <div className="container">
          <div className="row mt-40">
            <Switch>
              <Route path="/login">
                <div className="col-md-4 col-md-offset-4">
                  <Login></Login>
                </div>
              </Route>
              <Route path="/register">
                <div className="col-md-4 col-md-offset-4">
                  <Register></Register>
                </div>
              </Route>
              <Route path="/forget">
                <div className="col-md-4 col-md-offset-4">
                  <Forget></Forget>
                </div>
              </Route>
              <Route path="/registrar-tecnico">
                <div className="col-md-10 col-md-offset-1">
                  <RegisterTech />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}

export default Auth;
