export function setBanners(payload) {
  return {
    type: "BANNERS",
    payload: payload
  };
}

export function setMobileBanners(payload) {
  return {
    type: "MOBILE",
    payload: payload
  };
}
