import React, { Component } from "react";
import store from "../../store";
import { serverlink } from "./../../enviroment";
import { connect } from "react-redux";
import { deleteOneOfCart, addToCart } from "../../store/actions/cartActions";
import { IonButton, IonGrid, IonCol, IonRow } from "@ionic/react";

class ServiceGrid extends Component {
  constructor() {
    super();
    this.state = {
      quantity: "0",
      triggerPopup: false,
    };
    this.onMinus = this.onMinus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  onChange(e) {
    this.setState({ quantity: e.target.value });
  }
  onAdd() {
    if (this.state.quantity === 10) {
      alert("No puedes agregar más de 10 servicios de la misma cantidad");
    } else {
      this.setState({ quantity: parseInt(this.state.quantity) + 1 });
      store.dispatch(
        addToCart({
          id: this.props.id,
          name: this.props.name,
          base_cost: this.props.base_cost,
          description: this.props.description,
          quantity: 1,
          category: this.props.category,
          subcategory: this.props.subcategory,
          includes: this.props.includes,
          not_include: this.props.not_include,
        })
      );
    }
  }

  onMinus() {
    if (this.state.quantity == 0) {
    } else {
      this.setState({ quantity: parseInt(this.state.quantity) - 1 });
      store.dispatch(deleteOneOfCart(this.props.id));
    }
  }
  componentWillMount() {
    this.props.st_currentServices.map((x) => {
      if (x.id === this.props.id) {
        this.setState({ quantity: x.quantity });
      }
    });
  }
  render() {
    if (this.props.st_notSame && this.state.quantity != 0) {
      this.setState({ quantity: 0 });
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    return (
      <>
        <div
          className={
            (this.props.itemCount === 1 ? "col-md-offset-4 " : "") +
            "col-md-4 mb-20 col-xs-12 pdr-2"
          }
        >
          <div
            className="sh-l padeado20 pb-20 box-service-grid"
            style={{ minHeight: "320px" }}
          >
            <img
              src={this.props.image}
              className="img-responsive"
              style={{ borderBottom: "solid 4px #f2bb2f" }}
              alt=""
            />
            <p className="mt-10 fs-16 fr-1212 mhr-30 f600">{this.props.name}</p>
            <p className="text-right fs-16 fr-14" style={{ color: "#777" }}>
              <small className="fr-16">
                {this.state.quantity == 0
                  ? formatter.format(this.props.base_cost)
                  : formatter.format(
                      this.props.base_cost * this.state.quantity
                    )}
              </small>
            </p>

            <div className="center-responsive fr-12">
              Cantidad
              <div className="adition-box mtr-10">
                <IonGrid>
                  <IonRow>
                    <IonCol
                      className="text-right"
                      style={{ marginTop: "4px" }}
                      size="4"
                    >
                      <IonButton
                        size="small"
                        disabled={this.state.quantity < 1}
                        onClick={this.onMinus}
                      >
                        <i className="fa fa-minus"></i>
                      </IonButton>
                    </IonCol>
                    <IonCol className="text-left pdr-0" size="4">
                      <input
                        type="number"
                        value={this.state.quantity}
                        style={{
                          marginTop: "5px",
                          border: "none",
                        }}
                        className="form-control bolder"
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        name="quantity"
                      />
                    </IonCol>
                    <IonCol
                      className="text-left"
                      style={{ marginTop: "4px" }}
                      size="4"
                    >
                      <IonButton size="small" style={{}} onClick={this.onAdd}>
                        <i className="fa fa-plus"></i>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_currentServices: state.cart.currentServices,
    st_notSame: state.cart.notSame,
  };
};

export default connect(mapStateToProps)(ServiceGrid);
