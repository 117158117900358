import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { serverlink } from "../../enviroment";
import { Carousel } from "react-responsive-carousel";
import garantizado from "../../img/garantizado.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { setProductCategoryName } from "../../store/actions/productsActions";
import { pageView } from "./../../functions/GAFunctions";

class ProductCategories extends Component {
  state = {
    styles: {},
  };

  componentDidMount() {
    pageView("https://app.homepartner.com.co", "Home App");
  }

  render() {
    const bannersWeb = (
      <div className="hidden-xs" style={{ marginTop: "0px" }}>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showIndicators={false}
        >
          {this.props.st_bannersWeb.map((item) => (
            <div key={item.id}>
              <img style={{}} src={item.image} alt={"banner" + item.id} />
            </div>
          ))}
        </Carousel>
      </div>
    );
    const bannersResponsive = (
      <div className="visible-xs" style={{ marginTop: "-17px" }}>
        <Carousel
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3500}
          showIndicators={false}
        >
          {this.props.st_bannersResponsive.map((item) => (
            <div key={item.id}>
              <img src={item.image} alt={"banner" + item.id} />
            </div>
          ))}
        </Carousel>
      </div>
    );
    const menuItems = this.props.st_menuList.map((item) => (
      <div
        className="col-md-4 col-xs-4  pdr-2"
        style={{ marginTop: "10px" }}
        key={item.id}
      >
        <div
          className="grid-categories-top sh-b "
          style={{
            backgroundColor: "whitesmoke",
            borderStyle: "solid",
            borderColor: "#" + item.color,
            borderWidth: "2px",
          }}
        >
          <Link
            onClick={() => this.props.selectProductCategory(item.id)}
            to={"/tienda/" + item.id}
          >
            <p className="fs-12 text-center">
              <img
                className="img-subcategories img-responsive"
                src={item.image}
                style={{ margin: "0 auto", maxHeight: "90px" }}
                alt=""
              />
              <br />
              <h3
                className="fs-14"
                style={{ fontWeight: "600", minHeight: "30px" }}
              >
                {item.name}
              </h3>
            </p>
            <p className="fs-12 hidden-xs text-center" style={{}}>
              {item.description}
            </p>
          </Link>
        </div>
      </div>
    ));
    return (
      <>
        <div className="row" id="main">
          <div className="col-sm-12 col-md-12 no-padding" id="content">
            {bannersResponsive}
            {bannersWeb}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-md-offset-1" id="content">
              <div className="">
                <div className="mt-40 servicios mtr-20">
                  <div className="row mb-20">{menuItems}</div>
                  <div className="text-center">
                    <img
                      src={garantizado}
                      style={{ width: "125px" }}
                      alt="garantizado"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_menuList: state.products.listProductCategories,
    st_linkedCart: state.information.linkedCart,
    st_loggedIn: state.user.loggedIn,
    st_bannersWeb: state.templates.bannersWeb,
    st_bannersResponsive: state.templates.bannersResponsive,
  };
};

const setStateToProps = (dispatch) => {
  return {
    selectProductCategory: (categorySelected) =>
      dispatch(setProductCategoryName(categorySelected)),
  };
};
export default connect(mapStateToProps, setStateToProps)(ProductCategories);
