export const facebookAuthLink =
  "https://www.facebook.com/login.php?skip_api_login=1&api_key=2747201132009777&kid_directed_site=0&app_id=2747201132009777&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv3.3%2Fdialog%2Foauth%3Fclient_id%3D2747201132009777%26redirect_uri%3Dhttps%253A%252F%252Fplatform.homepartner.com.co%252Fcallback%26scope%3Demail%26response_type%3Dcode%26state%3DqTRUPbzX5CxAg3zR1LCtEcODDHCwecLmXgBVkfA2%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3Da218926a-e834-4b67-ac24-545ffe484c5c&cancel_url=https%3A%2F%2Fplatform.homepartner.com.co%2Fcallback%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3DqTRUPbzX5CxAg3zR1LCtEcODDHCwecLmXgBVkfA2%23_%3D_&display=page&locale=en_US&pl_dbl=0";

export const development = { server: "http://localhost:8080", ga: "" };

export const production = {
  server: "https://platform.homepartner.com.co",
  ga: "UA-148327383-1",
};

export const serverIp = "http://142.93.181.6";
