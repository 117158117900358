import React , { Component } from 'react'
import { Link } from 'react-router-dom';

class Forget extends Component {


  render() {
    return (
      <div className="card sh-b">
        <div className="card-title text-center mb-20 fs-30  bolder">
          <br />
          Olvide la contraseña
          <p className="fs-12 mt-10">Escribe tú correo de registro para poder enviarte el link de recuperación de contraseña</p>
					       </div>
        <div className="card-body text-center">
          <i className="fa fa-user" style={{ marginRight: "20px" }}></i><input type="text" placeholder="Email" className="mb-10" />
          <br />          
          <button className="btn btn-negro mb-10 mt-10">Enviar correo</button>          
        </div>
        <div className="card-footer text-center mt-20">
          <strong>Aún no tengo cuenta</strong> <br />
          
          <Link to="/register">Registrarme</Link>
        </div>
      </div>
    )
  }
}

export default Forget