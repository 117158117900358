import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../store";
import { getSystemCore } from "./../../functions/InformationFunctions";
import { Link } from "react-router-dom";
import { serverlink } from "../../enviroment";
import { Redirect } from "react-router-dom";
import {
  setProductCategoryName,
  setProductSubcategoryName,
} from "../../store/actions/productsActions";
import { IonCard, IonCardHeader } from "@ionic/react";

class ProductSubcategory extends Component {
  // Comprobamos si ya esta cargada la subcategoria en el state
  componentDidMount() {
    if (this.props.st_name === "") {
      getSystemCore().then(() => {
        const { identifier } = this.props.match.params;
        store.dispatch(setProductCategoryName(identifier));
      });
    }
  }
  render() {
    const { identifier } = this.props.match.params;
    let url = "";
    const listSubcategories = this.props.st_submenuList.map((item) => (
      <>
        {item.name == "UNICA"
          ? (url = "/tienda/" + identifier + "/subcategoria/" + item.id)
          : null}
        <div className="col-md-4">
          <Link
            onClick={() => this.props.selectCategory(item.id)}
            to={"/tienda/" + identifier + "/subcategoria/" + item.id}
            key={item.id}
            className="no-link"
          >
            <IonCard>
              <img
                src={item.image}
                alt={"img_" + item.name}
                className="img-responsive hidden-xs"
                style={{}}
              ></img>
              <IonCardHeader>
                {" "}
                <h5 className="f600">{item.name}</h5>
              </IonCardHeader>
            </IonCard>
          </Link>
        </div>
      </>
    ));
    return (
      <>
        <div className="row" id="main">
          {url.length > 0 ? <Redirect to={url}></Redirect> : null}
          <div className="col-sm-12 col-md-12" id="content">
            <div className="padeado">
              <h3 className="f2 fs-40 hidden-xs">
                <img
                  src={this.props.st_image}
                  style={{ width: "60px" }}
                  alt=""
                />{" "}
                <strong>{this.props.st_name}</strong>
              </h3>
              <h3 className="f2 fs-30 visible-xs text-center">
                <br /> <strong>{this.props.st_name}</strong>
              </h3>
              <p className="text-center visible-xs">Categorias</p>
              <p className="mt-20 hidden-xs">Categorias</p>

              <div className="mt-30 servicios">
                <div className="row">
                  {listSubcategories}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_submenuList: state.products.listProductSubcategories,
    st_name: state.products.productCategoryName,
    st_image: state.products.productCategoryImage,
  };
};

const setStateToProps = (dispatch) => {
  return {
    selectCategory: (categorySelected) =>
      dispatch(setProductSubcategoryName(categorySelected)),
  };
};
export default connect(mapStateToProps, setStateToProps)(ProductSubcategory);
