import {
  sendMessage,
  sendImageMessage,
} from "../../functions/SendingFunctions";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const triggerSendMessage = (data, messageRef) => {
  messageRef.current.clear();
  sendMessage(data).then();
};

export const triggerFileUpload = (fileRef) => {
  fileRef.current.click();
};

export const submitImage = ({ fileRef, request_id }) => {
  const files = Array.from(fileRef.current.files);
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("file", file);
  });
  formData.append("request_id", request_id);
  sendImageMessage(formData).then();
};
