import React, { Component } from "react";
import imgRequest from "./../../img/request.png";
import { cancelRequest, sendRate } from "./../../functions/SendingFunctions";
import { serverlink } from "../../enviroment";
import techPng from "../../img/technician.png";
import ReactStars from "react-rating-stars-component";
import CancellingPopUP from "../Popups/cancellation";
import { Link } from "react-router-dom";

class ServiceLandscape extends Component {
  constructor() {
    super();
    this.state = {
      visible: true,
      rating: false,
      newRating: 5,
      comment: "Me encuentro muy satisfecho con el servicio",
      popup: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    sendRate({
      request_id: this.props.request.id,
      comment_user: this.state.comment,
      rate_user: this.state.newRating,
    }).then(this.props.historialCallback());
  }

  render() {
    const cancelingRequest = (note) => {
      cancelRequest({
        request_id: this.props.request.id,
        note: note,
      }).then(this.setState({ popup: false, visible: false }));
    };

    const hidePop = () => this.setState({ popup: false });
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    return (
      <div>
        {this.state.popup ? (
          <CancellingPopUP
            hidePop={hidePop}
            cancel={(cancel) => cancelingRequest(cancel)}
          ></CancellingPopUP>
        ) : null}
        {this.state.visible ? (
          <div
            className="sh-l padeado20 pb-20 mt-20"
            style={{ backgroundColor: "#f7f7f7", paddingTop: "10px" }}
          >
            {this.state.rating ? (
              <div>
                <form onSubmit={this.onSubmit}>
                  <h3 className=" mb-20">¿Cómo te fue con este servicio?</h3>

                  <div className="form-group">
                    <label>Calificación otorgada</label>
                    <ReactStars
                      count={5}
                      value={this.state.newRating}
                      onChange={(newRating) =>
                        this.setState({ newRating: newRating })
                      }
                      half={false}
                      size={28}
                      color2={"#ffd700"}
                    />
                  </div>
                  <div className="form-group" style={{ maxWidth: "400px" }}>
                    <label>Comentario</label>
                    <textarea
                      className="form-control"
                      value={this.state.comment}
                      onChange={(comment) =>
                        this.setState({ comment: comment.value })
                      }
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-negro">
                      Enviar <i className="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-2 hidden-xs">
                    <img
                      className="img-responsive"
                      src={imgRequest}
                      alt="imagen-servicio"
                    ></img>
                  </div>
                  <div
                    className="col-md-8 col-xs-10 fr-12"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {this.props.request.state_id == 1 ? (
                      <strong className="fs-22 fr-16 f600">
                        Servicio Pendiente
                      </strong>
                    ) : null}
                    {this.props.request.state_id == 2 ? (
                      <strong className="fs-22 fr-16 f600">
                        Servicio con Técnico aceptado
                      </strong>
                    ) : null}
                    {this.props.request.state_id == 3 ? (
                      <strong className="fs-22 fr-16 f600">
                        Servicio Completado
                      </strong>
                    ) : null}
                    {this.props.request.state_id == 4 ? (
                      <strong className="fs-22 fr-16 f600">
                        Servicio Cancelado
                      </strong>
                    ) : null}
                    <br />
                    <strong className="f600">Descripción</strong> <br />
                    {this.props.request.content_service} <br />
                    <strong className="f600">Fecha: </strong>
                    {this.props.request.datetime} <br />
                    {this.props.address ? (
                      <>
                        <strong className="f600">Dirección: </strong>
                        {this.props.address.address +
                          " " +
                          this.props.address.description}{" "}
                        <br />{" "}
                      </>
                    ) : (
                      <>
                        <strong className="f600">Dirección: </strong>Dirección
                        eliminada <br />
                      </>
                    )}
                    <strong className="f600">{this.props.request.notes}</strong>
                  </div>
                  <div className="col-xs-2 text-right">
                    <p
                      className="text-right fs-22 fr-14 f600"
                      style={{ color: "rgb(117, 117, 117)" }}
                    >
                      <small>
                        {formatter.format(this.props.request.final_price)}
                      </small>
                    </p>
                    <br />
                    <Link to={"/solicitud/" + this.props.request.id}>
                      <button
                        className="btn btn-negro"
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          padding: "5px 10px",
                          marginRight: "30px",
                        }}
                      >
                        <i className="fa fa-comments"></i>
                      </button>
                    </Link>
                    {this.props.cancelable ? (
                      <>
                        <button
                          className="btn btn-rojo hidden-xs"
                          style={{ fontSize: "12px" }}
                          onClick={() => {
                            this.setState({ popup: true });
                          }}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-rojo visible-xs"
                          style={{
                            fontSize: "12px",
                            minWidth: "auto",
                            padding: "5px 10px",
                          }}
                          onClick={() => {
                            this.setState({ popup: true });
                          }}
                        >
                          X
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
                {this.props.tech ? (
                  <div>
                    <div className="raya mt-10"></div>
                    <div className="row mt-20">
                      <div className="col-xs-2">
                        {this.props.techImage !== null ? (
                          <img
                            className="img-responsive"
                            src={this.props.techImage}
                            alt="imagen-tecnico"
                          />
                        ) : (
                          <img
                            className="img-responsive"
                            src={techPng}
                            alt="imagen-tecnico"
                          />
                        )}
                      </div>
                      <div className="col-xs-8 fr-12">
                        <strong className="f2 raya fs-20 fr-14 f600">
                          {this.props.techName}
                        </strong>{" "}
                        <br />
                        {this.props.techBio}
                        {this.props.rateable ? (
                          <div>
                            <p>No has realizado la calificación aún.</p>
                            <button
                              onClick={() => this.setState({ rating: true })}
                              className="btn btn-negro"
                              style={{ fontSize: "12px" }}
                            >
                              Calificar
                            </button>
                          </div>
                        ) : (
                          <div>
                            <ReactStars
                              className="reactivestars"
                              count={5}
                              value={this.props.request.rate_user}
                              edit={false}
                              half={true}
                              size={28}
                              color2={"#ffd700"}
                            />
                            <i>{this.props.request.comment_user}</i> <br />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.props.accepted ? (
                  <div>
                    <div className="raya mt-10"></div>
                    <div className="row mt-20">
                      <div className="col-xs-2">
                        {this.props.techImage !== null ? (
                          <img
                            className="img-responsive"
                            src={this.props.techImage}
                            alt="imagen-tecnico"
                          />
                        ) : (
                          <img
                            className="img-responsive"
                            src={techPng}
                            alt="imagen-tecnico"
                          />
                        )}
                      </div>
                      <div className="col-xs-8">
                        <strong className="f2 raya fs-20 f600">
                          {this.props.techName}
                        </strong>{" "}
                        <br />
                        {this.props.techBio}
                        <div>
                          <ReactStars
                            className="reactivestars"
                            count={5}
                            value={this.props.rate}
                            edit={false}
                            half={true}
                            size={28}
                            color2={"#ffd700"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ServiceLandscape;
