export function selectCategory(item) {
  return {
    type: "SELECT_CATEGORY",
    payload: { value: item }
  };
}

export function selectSubcategory(item) {
  return {
    type: "SELECT_SUBCATEGORY",
    payload: { value: item }
  };
}

export function updateCategories(payload) {
  return {
    type: "UPDATE_CATEGORIES",
    payload: payload
  };
}

export function clearRequest() {
  return { type: "CLEAR_REQUESTED" };
}
