export function loadingOn() {
  return { type: "LOADING" };
}

export function loadingOff() {
  return { type: "LOADING_OFF" };
}

export function setCartRedirect() {
  return { type: "SET_CART_REDIRECT" };
}

export function offLink() {
  return { type: "OFF_LINK" };
}

export function resetNotSame() {
  return { type: "RESET_NOT_SAME" };
}
