import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { register } from "./../../../functions/AuthFunctions";
import Swal from "sweetalert2-react";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "../../../store/actions/triggerActions";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      name: "",
      last_name: "",
      email: "",
      password: "",
      password2: "",
      cellphone: "",
      error: false,
      msgError: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.password != this.state.password2) {
      this.setState({
        error: true,
        msgError: "Las contraseñas no coinciden.",
      });
      return;
    }
    this.props.loadingOn();
    const dataSend = this.state;

    register(dataSend)
      .then((res) => {
        if (res.success) {
          this.setState({ success: true });
        } else {
          this.setState({
            error: true,
            msgError: res.msg,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: true,
          msgError: err.data,
        });
      });
  }

  render() {
    let boardSection = "";
    const normalUser = () => {
      this.setState({
        step: 2,
      });
    };
    const profesionalUser = () => {
      this.setState({
        step: 3,
      });
    };
    switch (this.state.step) {
      case 1:
        boardSection = (
          <div className="card sh-b">
            <div className="card-title text-center mb-20 fs-30 f2 bolder">
              <br />
              Registrarse
            </div>
            <div className="card-body ">
              <p className="text-center">
                <small>¿Cuentanos cómo deseas registrarte?</small>
              </p>
              <div
                onClick={normalUser}
                className="card sh-b hover-amarillo pointer"
                style={{ backgroundColor: "#f9f9f9c4" }}
              >
                <i className="fa fa-user"></i> Quiero encontrar un profesional
              </div>
              <a
                href="https://platform.homepartner.com.co/registrar-tecnico"
                className="no-link"
              >
                <div
                  className="card sh-b hover-amarillo mt-10 pointer"
                  style={{ backgroundColor: "#f9f9f9c4" }}
                >
                  <i className="fa fa-cog"></i> Soy profesional
                </div>
              </a>
            </div>
          </div>
        );
        break;
      case 2:
        boardSection = (
          <div className="card sh-b">
            <div className="card-title text-center mb-20 fs-30  bolder">
              <br />
              Registrarse
            </div>
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label className="label negro-c">Nombre</label>
                  <input
                    required="required"
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú nombre aquí"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">Apellido</label>
                  <input
                    required="required"
                    name="last_name"
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú apellido aquí"
                    value={this.state.last_name}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">Correo electrónico</label>
                  <input
                    required="required"
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Ingrese sú email aquí"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">Contraseña</label>
                  <input
                    required="required"
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Ingrese sú password aquí"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">Confirmar contraseña</label>
                  <input
                    required="required"
                    name="password2"
                    type="password"
                    className="form-control"
                    placeholder="Confirme sú password aquí"
                    value={this.state.password2}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">Número celular</label>
                  <input
                    required="required"
                    name="cellphone"
                    type="number"
                    className="form-control"
                    placeholder="Ingrese sú número celular aquí"
                    value={this.state.cellphone}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label className="label negro-c">
                    {" "}
                    <input type="checkbox" required /> Acepto los{" "}
                    <Link to="/terminos-y-condiciones">
                      terminos y condiciones
                    </Link>
                  </label>
                </div>
                <div className="form-group text-center">
                  <button className="btn btn-negro">Registrarme</button>
                </div>
              </form>
            </div>
            <div className="card-footer text-center mt-20">
              <strong>Ya tengo cuenta</strong> <br />
              <Link to="/login">Iniciar Sesión</Link>
            </div>
          </div>
        );
        break;

      case 3:
        boardSection = (
          <div className="card sh-b">
            <div className="card-title text-center mb-20 fs-30  bolder">
              <br />
              Registrarse Profesional
            </div>
            <div className="card-body">
              <div className="form-group">
                <label className="label negro-c">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese sú nombre aquí"
                />
              </div>
              <div className="form-group">
                <label className="label negro-c">Apellido</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese sú apellido aquí"
                />
              </div>
              <div className="form-group">
                <label className="label negro-c">Correo electrónico</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese sú email aquí"
                />
              </div>
              <div className="form-group">
                <label className="label negro-c">Contraseña</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese sú password aquí"
                />
              </div>
              <div className="form-group">
                <label className="label negro-c">Número celular</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese sú número celular aquí"
                />
              </div>
              <div className="form-group text-center">
                <button className="btn btn-negro">Registrarme</button>
              </div>
            </div>
            <div className="card-footer text-center mt-20">
              <strong>Ya tengo cuenta</strong> <br />
              <Link to="/login">Iniciar Sesión</Link>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <div>
        {boardSection}
        <Swal
          show={this.state.error}
          title="Error"
          text={this.state.msgError}
          icon="error"
          onConfirm={() => this.setState({ error: false })}
        ></Swal>
        <Swal
          show={this.state.success}
          title="Éxito"
          text="El usuario ha sido creado con éxito, igresa a la plataforma con tús datos"
          icon="success"
          onConfirm={() => this.setState({ redirect: true })}
        ></Swal>
        {this.state.redirect && <Redirect to="/login"></Redirect>}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadingOn: () => dispatch(loadingOn()),
    loadingOFF: () => dispatch(loadingOff()),
  };
};

export default connect(null, mapDispatchToProps)(Register);
