import React, { Component } from "react";
import ServiceLandscape from "../../components/ServiceLandscape";
import { getCanceled } from "../../functions/InformationFunctions";

class Canceled extends Component {
  state = {
    services: [],
    loading: true
  };
  componentDidMount() {}
  render() {
    if (this.state.services.length === 0 && this.state.loading) {
      getCanceled()
        .then(res => {
          this.setState({ loading: false });
          this.setState({ services: res.data.success });
        })
        .catch(res => {
          this.setState({ loading: false });
        });
    }
    return (
      <div className="row" id="main">
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40">
              <strong>Mis servicios Cancelados</strong>
            </h3>
            <p className="fs-20">
              A continuación encontraras información relacionada a tús servicios
              agendados, que se han cancelado.
            </p>
            <div className="mt-40 servicios">
              <div className="row">
                {this.state.loading ? (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>{" "}
                    <br />
                    <br />
                    Cargando
                  </div>
                ) : this.state.services.length > 0 ? (
                  this.state.services.map(item => (
                    <ServiceLandscape
                      key={item.request_service.id}
                      request={item.request_service}
                      address={item.address}
                      cancelable={false}
                      rateable={false}
                    ></ServiceLandscape>
                  ))
                ) : (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-times fa-3x fa-fw"></i> <br />
                    <br />
                    No tienes servicios aún
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Canceled;
