import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IonToast } from "@ionic/react";
import { sendRegisterTech } from "../../functions/SendingFunctions";

class RegisterTech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      last_name: "",
      identification_type: "",
      identification: "",
      picture: null,
      email: "",
      address: "",
      emergency_contact: "",
      certificate: null,
      aditional_info: "",
      password: "",
      password_confirm: "",
      cellphone: "",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFile = this.onFile.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onBlur() {
    const password = this.state.password;
    const password_confirm = this.state.password_confirm;
    if (password_confirm === "") {
      return;
    }
    if (password !== password_confirm) {
      this.setState({
        error: true,
        error_mesagge: "Las contraseñas no coinciden.",
      });
      return;
    }
    this.setState({ error: false });
  }
  onFormSubmit(e) {
    e.preventDefault();
    const data = this.state;
    sendRegisterTech(data).then((response) => {
      if (response.success) {
        this.setState({ exito: true, redirect: true });
      }
    });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onFile(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }
  render() {
    return (
      <div className="card sh-b mb-40">
        <div className="card-title text-center mb-20 fs-30  bolder">
          <br />
          Registrarse Técnico
        </div>
        <p className="text-center">
          Agradecemos el tiempo e interés en hacer parte de la red de técnicos
          profesionales más innovadora del país. Por favor diligenciar el
          siguiente formulario, si cumples con los requisitos necesarios,
          próximamente nos pondremos en contacto.
        </p>
        <div className="card-body">
          {this.state.error && (
            <IonToast
              isOpen={this.state.error}
              message={this.state.error_mesagge}
              duration={0}
            />
          )}
          <form onSubmit={this.onFormSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Nombre</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú nombre aquí"
                    onChange={this.onChange}
                    value={this.state.name}
                    required="required"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Apellidos</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú apellido aquí"
                    name="last_name"
                    onChange={this.onChange}
                    value={this.state.last_name}
                    required="required"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">
                    Tipo de identificación
                  </label>
                  <select
                    className="form-control"
                    name="identification_type"
                    value={this.state.identification_type}
                    onChange={this.onChange}
                    placeholder="Selecciona el tipo de identificación"
                    required="required"
                  >
                    <option value="CC">Cédula de ciudadania</option>
                    <option value="CE">Cédula de extranjería</option>
                    <option value="PEM">Permiso de empleo permanente</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">
                    Número de identificación
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Ingrese sú número de identificación aquí"
                    name="identification"
                    onChange={this.onChange}
                    value={this.state.identification}
                    required="required"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Correo electrónico</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú email aquí"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    required="required"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Número celular</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú número celular aquí"
                    onChange={this.onChange}
                    name="cellphone"
                    value={this.state.cellphone}
                    required="required"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Ingrese sú password aquí"
                    value={this.state.password}
                    onChange={this.onChange}
                    required="required"
                    name="password"
                    onBlur={this.onBlur}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Confirmar Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirme sú password aquí"
                    onChange={this.onChange}
                    value={this.password_confirm}
                    required="required"
                    name="password_confirm"
                    onBlur={this.onBlur}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Ciudad</label>
                  <select className="form-control" required="required">
                    <option>Bogotá</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Dirección</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese sú dirección"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange}
                    required="required"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Categoria</label>
                  <select className="form-control" required="required">
                    {this.props.st_categories.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Número de emergencia</label>
                  <input
                    className="form-control"
                    placeholder="Ingrese el número"
                    name="emergency_contact"
                    value={this.state.emergency_contact}
                    onChange={this.onChange}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Foto de usuario</label>
                  <input
                    className="form-control custom-file-input"
                    name="picture"
                    onChange={this.onFile}
                    type="file"
                    required="required"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">
                    Certificado de estudios (Opcional)
                  </label>
                  <input
                    className="form-control custom-file-input"
                    name="certificate"
                    onChange={this.onFile}
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label negro-c">Experiencia</label>
                  <textarea
                    name="aditional_info"
                    className="form-control"
                    value={this.state.aditional_info}
                    onChange={this.onChange}
                    placeholder="Escriba acerca de sus dos últimos trabajos."
                  />
                </div>
              </div>
            </div>

            <div className="form-group text-center">
              <label htmlFor="check">
                <input id="check" type="checkbox" required="required"></input>
                <small className="f300">
                  Al registrarte aceptas nuestros{" "}
                  <Link to="/terminos-y-condiciones">
                    Terminos y condiciones
                  </Link>
                </small>
              </label>
              <br />
              <button
                className="btn btn-negro"
                disabled={this.state.error && "disabled"}
              >
                Registrarme
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer text-center mt-20">
          <strong>Ya tengo cuenta</strong> <br />
          <Link to="/login">Iniciar Sesión</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_categories: state.information.listCategories,
  };
};

export default connect(mapStateToProps)(RegisterTech);
