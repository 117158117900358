import React, { Component } from "react";
import { connect } from "react-redux";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonRow,
  IonCol,
  IonButton,
  IonGrid,
} from "@ionic/react";
import { Carousel } from "react-responsive-carousel";
import { serverlink } from "../../enviroment";
import { getSystemCore } from "./../../functions/InformationFunctions";
import store from "../../store";
import { setProductCategoryByProduct } from "../../store/actions/productsActions";
import ReactHtmlParser from "react-html-parser";
import { addProductToCart } from "../../store/actions/cartActions";
import PopUpRelated from "../../components/Popups/related_services";
import { Redirect } from "react-router-dom";

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      quantity: 1,
      popUp: false,
      redirect: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onAddQuantity = this.onAddQuantity.bind(this);
    this.onMinusQuantity = this.onMinusQuantity.bind(this);
    this.onHidePop = this.onHidePop.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onBlur(e) {
    if (e.target.value < 1) {
      return this.setState({ [e.target.name]: 1 });
    }
  }

  onHidePop() {
    this.setState({ popUp: false });
  }

  onAddQuantity() {
    this.setState({ quantity: this.state.quantity + 1 });
  }
  onMinusQuantity() {
    this.setState({ quantity: this.state.quantity - 1 });
  }

  onAddToCart() {
    const data = {
      id: this.props.st_product.id,
      name: this.props.st_product.name,
      price_base: this.props.st_product.price_base,
      quantity: this.state.quantity,
    };
    store.dispatch(addProductToCart(data));
    if (this.props.st_product.services.length > 0) {
      this.setState({ popUp: true });
      return;
    }
    this.setState({ redirect: true });
  }

  // Set Category and Subcategory, and get items from store
  componentWillMount() {
    const { identifier } = this.props.match.params;
    if (this.props.st_product.name == "") {
      getSystemCore().then(() => {
        store.dispatch(setProductCategoryByProduct(identifier));
      });
    } else {
      store.dispatch(setProductCategoryByProduct(identifier));
    }
  }

  render() {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    return (
      <>
        {this.state.redirect && <Redirect to="/confirmar-compra" />}
        {this.props.st_product.services.length > 0 && this.state.popUp && (
          <PopUpRelated
            type="PRODUCT"
            services={this.props.st_product.services}
            hidePop={this.onHidePop}
          />
        )}

        <div className="row">
          <div className="col-md-5">
            <IonCard>
              {this.props.st_product.files.length === 0 ? (
                <img
                  className="img-responsive"
                  src={this.props.st_product.picture}
                  alt={"producto" + this.props.st_product.name}
                />
              ) : (
                <Carousel
                  showArrows={true}
                  showThumbs={true}
                  showStatus={false}
                  infiniteLoop={false}
                  autoPlay={true}
                  interval={3500}
                  showIndicators={true}
                >
                  <div>
                    <img
                      src={this.props.st_product.picture}
                      alt={"producto_" + this.props.st_product.name}
                    />
                  </div>
                  {this.props.st_product.files.map((item) => (
                    <div key={item.id}>
                      <img src={item.url} alt={"producto" + item.id} />
                    </div>
                  ))}
                </Carousel>
              )}
              <IonCardHeader className="visible-xs">
                <div className="row">
                  <div className="col-xs-8">
                    <IonCardSubtitle>
                      {this.props.st_productCategory}
                    </IonCardSubtitle>
                    <IonCardTitle>{this.props.st_product.name}</IonCardTitle>
                  </div>
                  <div className="col-xs-4">
                    <IonText
                      className="text-left fs-22 mt-20 f600"
                      style={{ float: "right" }}
                    >
                      {formatter.format(
                        this.props.st_product.price_base * this.state.quantity
                      )}
                    </IonText>
                  </div>
                </div>
              </IonCardHeader>
              <IonCardContent className="visible-xs">
                {ReactHtmlParser(this.props.st_product.short_description)}
                <br />
                <div className="row">
                  <div className="col-xs-6 pdr-0 text-right">
                    <IonGrid>
                      <IonRow>
                        <IonCol
                          className="text-left"
                          style={{ marginTop: "0px" }}
                          size="4"
                        >
                          <IonButton
                            size="small"
                            disabled={this.state.quantity < 2}
                            onClick={this.onMinusQuantity}
                          >
                            <i className="fa fa-minus"></i>
                          </IonButton>
                        </IonCol>
                        <IonCol className="text-left pdr-0" size="4">
                          <input
                            type="number"
                            value={this.state.quantity}
                            style={{
                              marginTop: "5px",
                              border: "none",
                            }}
                            className="form-control bolder"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            name="quantity"
                          />
                        </IonCol>
                        <IonCol
                          className="text-left"
                          style={{ marginTop: "0px" }}
                          size="4"
                        >
                          <IonButton
                            size="small"
                            style={{}}
                            onClick={this.onAddQuantity}
                          >
                            <i className="fa fa-plus"></i>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                  <div className="col-xs-6 text-center pt-12">
                    <button
                      className="btn btn-negro text-uppercase f600 fs-12"
                      style={{ fontSize: "12px" }}
                      onClick={this.onAddToCart}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
          <div className="col-md-7">
            <IonCard className="mb-70">
              <IonCardHeader className="hidden-xs">
                <div className="row">
                  <div className="col-xs-8">
                    <IonCardSubtitle>
                      {this.props.st_productCategory}
                    </IonCardSubtitle>
                    <IonCardTitle>{this.props.st_product.name}</IonCardTitle>
                  </div>
                  <div className="col-xs-4">
                    <IonText
                      className="text-left fs-22 mt-20 f600"
                      style={{ float: "right" }}
                    >
                      {formatter.format(
                        this.props.st_product.price_base * this.state.quantity
                      )}
                    </IonText>
                  </div>
                </div>
              </IonCardHeader>
              <IonCardContent className="hidden-xs">
                {ReactHtmlParser(this.props.st_product.short_description)}
                <br />
                <div className="row">
                  <div className="col-xs-6 text-right">
                    <div
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                      <IonRow>
                        <IonCol className="text-left">
                          <IonButton
                            disabled={this.state.quantity < 2}
                            onClick={this.onMinusQuantity}
                          >
                            <i className="fa fa-minus"></i>
                          </IonButton>
                        </IonCol>
                        <IonCol className="text-center">
                          <input
                            type="number"
                            value={this.state.quantity}
                            style={{
                              marginTop: "5px",
                              border: "none",
                            }}
                            className="form-control text-center bolder"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            name="quantity"
                          />
                        </IonCol>
                        <IonCol className="text-right">
                          <IonButton onClick={this.onAddQuantity}>
                            <i className="fa fa-plus"></i>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </div>
                  </div>
                  <div className="col-xs-6 text-right">
                    <button
                      className="btn btn-negro text-uppercase f600"
                      onClick={this.onAddToCart}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle>Descripción</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <div className="row">
                  <div className="col-xs-6">
                    <label>Referencia</label>
                    <p>{this.props.st_product.reference}</p>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-xs-6">
                    <label>Peso</label>
                    <p>{this.props.st_product.weight} g</p>
                  </div>
                  <div className="col-xs-6">
                    <label>Altura</label>
                    <p>{this.props.st_product.height} cm</p>
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-xs-12">
                    <label>Producto</label>
                    <p>
                      {ReactHtmlParser(this.props.st_product.long_description)}
                    </p>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_productCategory: state.products.productCategoryName,
    st_product: state.products.product,
    st_cartProducts: state.cart.currentProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
