import React, { Component } from "react";
import { connect } from "react-redux";
import { getSystemCore } from "./../../functions/InformationFunctions";
import store from "../../store";
import ServiceGrid from "./../../components/ServiceGrid";
import { Link, Redirect } from "react-router-dom";
import { serverlink } from "../../enviroment";
import {
  selectCategory,
  selectSubcategory,
} from "../../store/actions/informationActions";
import { setCartRedirect } from "../../store/actions/triggerActions";
import PopUpRelated from "../../components/Popups/related_products";
import BackMovil from "../../components/Back/BackMovil";

class Services extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      popUp: false,
      redirect: false,
    };
    this.onContinue = this.onContinue.bind(this);
    this.onHidePop = this.onHidePop.bind(this);
  }

  onContinue(products) {
    // check if the services selected have some related products
    if (products.length > 0) {
      this.setState({
        products: products,
        popUp: true,
      });
      return;
    }
    this.setState({ redirect: true });
  }

  onHidePop() {
    this.setState({ popUp: false });
  }
  componentDidMount() {
    if (this.props.st_listServices.length == 0) {
      getSystemCore().then(() => {
        const { categoria } = this.props.match.params;
        const { subcategoria } = this.props.match.params;
        store.dispatch(selectCategory(categoria));
        store.dispatch(selectSubcategory(subcategoria));
      });
    } else {
      const { categoria } = this.props.match.params;
      const { subcategoria } = this.props.match.params;
      store.dispatch(selectCategory(categoria));
      store.dispatch(selectSubcategory(subcategoria));
    }
  }
  render() {
    const { categoria } = this.props.match.params;
    const { subcategoria } = this.props.match.params;
    const servicesGrid = this.props.st_listServices.map((item, index) => (
      <ServiceGrid
        key={item.id}
        image={item.image}
        name={item.name}
        description={item.description}
        id={item.id}
        category={categoria}
        subcategory={subcategoria}
        base_cost={item.base_cost}
        counter={index}
        includes={item.pop_includes}
        not_include={item.pop_not_include}
        onContinue={() => this.onContinue(item.products)}
      ></ServiceGrid>
    ));
    return (
      <>
        <BackMovil />
        {this.state.redirect && <Redirect to="/confirmar-compra" />}
        {this.state.products.length > 0 && this.state.popUp && (
          <PopUpRelated
            products={this.state.products}
            hidePop={this.onHidePop}
          />
        )}
        <div className="row" id="main">
          <div className="col-sm-12 col-md-12" id="content">
            <div className="padeado">
              <h3 className="f2 fs-40 hidden-xs">
                <img
                  src={this.props.st_categoryImage}
                  style={{ width: "60px" }}
                  alt=""
                />
                <img
                  src={this.props.st_subCategoryImage}
                  style={{
                    width: "30px",
                    marginTop: "35px",
                    marginLeft: "-15px",
                  }}
                  alt=""
                />
                <strong style={{ marginLeft: "20px" }}>
                  Crear solicitud de {this.props.st_categoryName}
                </strong>
              </h3>
              {/* responsive */}
              <h3 className="f2 fs-22 visible-xs text-center">
                <img
                  src={this.props.st_categoryImage}
                  className="\"
                  style={{ width: "60px" }}
                  alt=""
                />
                <br />
                <strong>Crear solicitud de {this.props.st_categoryName}</strong>
              </h3>
              <p className="fs-20 fr-14 center-responsive">
                ¿Qué servicio necesitas?
              </p>
              <div className="text-center visible-xs">
                <Link
                  to={"/sugerencias"}
                  className="btn btn-azul center-responsive"
                  style={{ fontSize: "14px" }}
                >
                  ¿No encuentro el servicio?
                </Link>
              </div>
              <div className="text-right hidden-xs">
                <Link
                  to="/sugerencias"
                  className="btn btn-azul center-responsive"
                  style={{ fontSize: "14px" }}
                >
                  ¿No encuentro el servicio?
                </Link>
              </div>

              <div className="mt-40 mtr-20 servicios">
                <div className="row">{servicesGrid}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    st_categoryImage: state.information.categoryImage,
    st_listServices: state.information.listServices,
    st_subCategoryName: state.information.subCategoryName,
    st_subCategoryImage: state.information.subCategoryImage,
    st_currentServices: state.cart.currentServices,
    st_categoryName: state.information.categoryName,
  };
};
const setStateToProps = (dispatch) => {
  return {
    linkedCart: () => dispatch(setCartRedirect()),
  };
};
export default connect(mapStateToProps, setStateToProps)(Services);
