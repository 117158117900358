import React, { Component } from "react";
import { sendSuggestion } from "../../functions/SendingFunctions";
import { Link } from "react-router-dom";

class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      name: "",
      contact: "",
      description: "",
      contact_type: "WHATSAPP",
      email: "",
      category: "ELECTRICIDAD"
    };
    this.onChange = this.onChange.bind(this);
    this.onType = this.onType.bind(this);
    this.onSendForm = this.onSendForm.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onType(e) {
    if (e.target.checked) {
      this.setState({ contact_type: e.target.value });
    }
  }

  onSendForm(e) {
    e.preventDefault();
    sendSuggestion({
      name: this.state.name,
      contact: this.state.contact,
      description: this.state.description,
      contact_type: this.state.contact_type,
      category: this.state.category,
      email: this.state.email,
      success: false,
      error: false
    })
      .then(() => {
        this.setState({ success: true });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12" style={{ paddingBottom: "100px" }}>
          <div className="padeado">
            <h3 className="f2 fs-40 mtr-55 f900">No encuentro el servicio</h3>
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "whitesmoke",
                padding: "20px",
                marginTop: "35px"
              }}
            >
              {this.state.success ? (
                <div>
                  <h3 className="f2 fs-40 fr-18">
                    <strong>Éxito</strong>
                  </h3>
                  <p className="fs-20 fr-16">
                    Tú sugerencia ha sido enviada, pronto te contactaremos.
                  </p>

                  <Link to="/">
                    <button className="btn btn-negro mt-20">
                      Regresar al inicio <i className="fa fa-arrow-left"></i>
                    </button>
                  </Link>
                </div>
              ) : this.state.error ? (
                <div>
                  <h3 className="f2 fs-40 fr-18">
                    <strong>Error</strong>
                  </h3>
                  <p className="fs-20 fr-16">
                    Tú sugerencia no ha sido enviada, vuelve a intentarlo más
                    tarde.
                  </p>

                  <Link to="/">
                    <button className="btn btn-negro mt-20">
                      Regresar al inicio <i className="fa fa-arrow-left"></i>
                    </button>
                  </Link>
                </div>
              ) : (
                <div>
                  <p className="text-left fs-14">
                    Sabemos que hay servicios muy especificos, ingresa los
                    siguientes datos y te ayudaremos con tú necesidad!
                  </p>
                  <div className="row mt-30">
                    <form onSubmit={this.onSendForm}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cuál es tú nombre?</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            required=""
                            value={this.state.name}
                            onChange={this.onChange}
                            placeholder="Ingresa tú nombre"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cuál es tú celular?</label>
                          <input
                            className="form-control"
                            type="number"
                            name="contact"
                            required=""
                            value={this.state.contact}
                            onChange={this.onChange}
                            placeholder="Ingresa tú teléfono celular"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cuál es tú correo?</label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            required=""
                            value={this.state.email}
                            onChange={this.onChange}
                            placeholder="Ingresa tú correo electrónico"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cuál es la categoria?</label>
                          <select
                            className="form-control"
                            name="category"
                            value={this.state.category}
                            onChange={this.onChange}
                          >
                            <option value="ELECTRICIDAD">Electricidad</option>
                            <option value="PLOMERIA">Plomería</option>
                            <option value="CERRAJERIA">Cerrajería</option>
                            <option value="PINTURA">Pintura</option>
                            <option value="INSTALACIONES">Instalaciones</option>
                            <option value="OTRA">Otra</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cuál es el servicio que no encuentras?</label>
                          <textarea
                            className="form-control"
                            type="text"
                            name="description"
                            value={this.state.description}
                            onChange={this.onChange}
                            placeholder="Describe el problema, o servicio"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>¿Cómo deseas que te contactemos?</label>
                          <div className="form-group mt-20">
                            <input
                              defaultChecked
                              value="WHATSAPP"
                              onChange={this.onType}
                              id="whapp"
                              type="radio"
                              name="contact_type"
                            />
                            <label
                              className="f300"
                              style={{ marginLeft: "5px" }}
                              htmlFor="whapp"
                            >
                              Quiero que me escriban por Whatsapp
                            </label>
                            <br />
                            <input
                              value="TELEFONO"
                              onChange={this.onType}
                              id="tel"
                              type="radio"
                              name="contact_type"
                            />
                            <label
                              className="f300"
                              style={{ marginLeft: "5px" }}
                              htmlFor="tel"
                            >
                              Quiero que me llamen
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="col-md-6 center-responsive mt-20">
                        <button type="submit" className="btn btn-negro">
                          Enviar <i className="fa fa-arrow-right"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Suggestion;
