import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../store";
import { getSystemCore } from "./../../functions/InformationFunctions";
import { Link } from "react-router-dom";
import { serverlink } from "../../enviroment";
import { Redirect } from "react-router-dom";
import BackMovil from "../../components/Back/BackMovil";
import {
  selectCategory,
  selectSubcategory,
} from "../../store/actions/informationActions";

class SubcategoriesComponent extends Component {
  // Comprobamos que la categoria este cargada en el state si no la cargamos
  componentDidMount() {
    if (this.props.st_name === "") {
      getSystemCore().then(() => {
        const { identifier } = this.props.match.params;
        store.dispatch(selectCategory(identifier));
      });
    }
  }
  render() {
    const { identifier } = this.props.match.params;
    let url = "";
    const listSubcategories = this.props.st_submenuList.map((item) => (
      <div className="col-md-6" key={item.id}>
        {item.name == "UNICA"
          ? (url = "/categoria/" + identifier + "/subcategoria/" + item.id)
          : null}
        <Link
          onClick={() => this.props.selectCategory(item.id)}
          to={"/categoria/" + identifier + "/subcategoria/" + item.id}
        >
          <div
            className=" grid-categories"
            style={{
              border: "solid 1px #f1b82c",
              borderRadius: "23px",
              marginBottom: "10px",
            }}
          >
            <p className="fs-30">
              <img src={item.image} alt="" style={{ width: "110px" }} />{" "}
              {item.name}
            </p>
          </div>
        </Link>
      </div>
    ));
    return (
      <div className="row" id="main">
        <BackMovil />
        {url.length > 0 ? <Redirect to={url}></Redirect> : null}
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40 hidden-xs">
              <img src={this.props.st_image} style={{ width: "60px" }} alt="" />{" "}
              <strong>Crear solicitud de {this.props.st_name}</strong>
            </h3>
            <h3 className="f2 fs-20 visible-xs text-center">
              <img src={this.props.st_image} style={{ width: "60px" }} alt="" />
              <br /> <strong>Crear solicitud de {this.props.st_name}</strong>
            </h3>
            <p className="fs-20">
              ¿En qué parte de la casa necesitas este servicio?
            </p>
            <div className="mt-40 servicios">
              <div className="row">
                {listSubcategories}
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_submenuList: state.information.listSubcategories,
    st_name: state.information.categoryName,
    st_image: state.information.categoryImage,
  };
};

const setStateToProps = (dispatch) => {
  return {
    selectCategory: (categorySelected) =>
      dispatch(selectSubcategory(categorySelected)),
  };
};
export default connect(
  mapStateToProps,
  setStateToProps
)(SubcategoriesComponent);
