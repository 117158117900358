import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendAddress, deleteAddress } from "./../../functions/SendingFunctions";
import { loadUserData } from "../../functions/AuthFunctions";
import PopupAddress from "../../components/Address/Popup";
import ListAddresses from "../../components/Address/List";
import Avatar from "../../img/avatar.jpg";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      editInformatio: false,
      addressAdd: false,
      direction: false,
      addressName: "",
      addressDescription: "",
      addressAddress: "",
    };
    this.onSendAddress = this.onSendAddress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDeleteAddress = this.onDeleteAddress.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSendAddress(childState) {
    let data = {
      name: childState.addressName,
      description: childState.addressDescription,
      address: childState.addressAddress,
      city_id: childState.city,
    };
    sendAddress(data).then((res) => {
      if (res.success) {
        loadUserData();
        this.setState({ addressAdd: false });
      }
    });
  }

  onDeleteAddress(identificador) {
    let data = {
      address_id: identificador,
    };
    deleteAddress(data).then((res) => {
      if (res.success) {
        loadUserData();
        this.setState({ direction: false });
      }
    });
  }
  render() {
    return (
      <div className="row mtr-neg" id="main">
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40 mtr-55">
              <strong
                className="nml center-responsive bolder fr40"
                style={{ marginLeft: "20px", fontSize: "40px!important" }}
              >
                Perfil
              </strong>
            </h3>
            {this.state.addressAdd ? (
              <PopupAddress
                hidePop={() => this.setState({ addressAdd: false })}
                sendAddress={this.onSendAddress}
              ></PopupAddress>
            ) : (
              <div className="mt-40 mtr-0 servicios">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className=" pb-20 pbr-60"
                      style={{
                        paddingTop: "10px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row pt-20">
                            <div className="col-md-12">
                              <div className="box-cart">
                                <div className="text-center">
                                  {this.props.st_userData.picture == null ? (
                                    <img
                                      src={Avatar}
                                      style={{
                                        margin: "auto 0px",
                                        borderRadius: "90px",
                                        width: "120px",
                                      }}
                                      alt="profile"
                                    />
                                  ) : (
                                    <img
                                      src={this.props.st_userData.picture.replace(
                                        "normal",
                                        "large"
                                      )}
                                      alt="profile"
                                      style={{
                                        margin: "auto 0px",
                                        borderRadius: "90px",
                                        width: "120px",
                                      }}
                                    />
                                  )}

                                  <br />
                                  <br />
                                  <p className="f600">
                                    {this.props.st_userData.name}
                                  </p>
                                </div>

                                <p className="mt-40">
                                  <strong>Pedidos solicitados</strong>
                                  <br />
                                  {this.props.st_services.total} Servicios
                                </p>
                                <p className="">
                                  <strong>Pedidos cancelados</strong>
                                  <br />
                                  {this.props.st_services.cancelled} Servicios
                                </p>
                                <p className="">
                                  <strong>Servicios completados</strong>
                                  <br />
                                  {this.props.st_services.completed} Servicios
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="row pt-20">
                            <div className="col-md-12">
                              <div className="box-cart">
                                <p className="mt-10 fs-18 fr-16">
                                  <strong className="">
                                    Mis datos personales
                                  </strong>
                                </p>
                                <p className="">
                                  <strong className="f600">Correo: </strong>

                                  {this.props.st_userData.email}
                                </p>
                                <p className="">
                                  <strong className="f600">Teléfono: </strong>
                                  {this.props.st_userData.cellphone === null ? (
                                    <small>
                                      No se ha asignado un telefono.
                                    </small>
                                  ) : (
                                    this.props.st_userData.cellphone
                                  )}
                                </p>
                                <p className="">
                                  <strong className="f600">
                                    Fecha de cumpleaños:{" "}
                                  </strong>

                                  {this.props.st_userData.birthday === null ? (
                                    <small>No se ha asignado una fecha</small>
                                  ) : (
                                    this.props.st_userData.birthday
                                  )}
                                </p>
                                <br />
                                <div className="form-group">
                                  <Link
                                    onClick={() =>
                                      this.setState({ direction: true })
                                    }
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Editar datos personales
                                  </Link>
                                </div>
                              </div>
                              <div className="box-cart">
                                <p className="mt-10 fs-18 fr-16">
                                  <strong className="">Mis direcciones</strong>
                                </p>
                                <div className="form-group">
                                  {this.props.st_addresses.length > 0 ? (
                                    <div>
                                      {this.props.st_addresses.map((item) => (
                                        <ListAddresses
                                          key={item.id}
                                          name={item.name}
                                          id={item.id}
                                          address={item.address}
                                          changeAddress={this.onAddress}
                                          description={item.description}
                                          onDelete={() =>
                                            this.onDeleteAddress(item.id)
                                          }
                                        />
                                      ))}
                                      <br />
                                      <Link
                                        onClick={() =>
                                          this.setState({ addressAdd: true })
                                        }
                                        style={{
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Agregar nueva dirección
                                      </Link>
                                    </div>
                                  ) : (
                                    <Link
                                      onClick={() =>
                                        this.setState({ addressAdd: true })
                                      }
                                      style={{ color: "gray" }}
                                    >
                                      Agregar nueva dirección
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    st_userData: state.user.userData,
    st_addresses: state.user.addresses,
    st_services: state.user.userServices,
  };
};
const setStateToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, setStateToProps)(Profile);
