import React, { Component } from "react";
import { getQualify } from "../../functions/InformationFunctions";
import ServiceLandscape from "../../components/ServiceLandscape";

class Qualify extends Component {
  state = {
    services: [],
    loading: true,
  };
  render() {
    const { service } = this.props.match.params;
    const updateHistorial = () => {
      this.setState({ loading: true });
      getQualify(service)
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ services: res.data.success });
        })
        .catch((res) => {
          this.setState({ loading: false });
        });
    };

    if (this.state.services.length === 0 && this.state.loading) {
      getQualify(service)
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ services: res.data.success });
        })
        .catch((res) => {
          this.setState({ loading: false });
        });
    }
    return (
      <div className="row" id="main">
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40">
              <strong>Calificar servicio</strong>
            </h3>
            <p className="fs-20">
              Nos encanta saber como te fue en el servicio, califica a tu
              servicio.
            </p>
            <div className="mt-40 servicios">
              <div className="row">
                {this.state.loading ? (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>{" "}
                    <br />
                    <br />
                    Cargando
                  </div>
                ) : this.state.services.length > 0 ? (
                  this.state.services.map((item) => (
                    <ServiceLandscape
                      historialCallback={() => updateHistorial}
                      key={item.request_service.id}
                      request={item.request_service}
                      address={item.address}
                      cancelable={false}
                      rateable={item.request_service.rate_user === null}
                      techName={
                        item.technician.name + " " + item.technician.last_name
                      }
                      techImage={item.technician.picture}
                      techBio={item.technician.bio}
                      tech={true}
                    ></ServiceLandscape>
                  ))
                ) : (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-times fa-3x fa-fw"></i> <br />
                    <br />
                    Este servicio ya ha sido calificado.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Qualify;
