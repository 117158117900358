import { GA } from "../enviroment";

export const pageView = (url, page) => {
  window.gtag("config", GA, {
    page_path: url,
    page_title: page,
  });
};

export const sendEvent = (event) => {
  window.gtag("event", event, {
    event_category: "App_events",
    event_label: "Aplication",
  });
};
