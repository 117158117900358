export function addRedirect(item) {
  return {
    type: "ADD_REDIRECT",
    payload: item,
  };
}

export function clearRedirect() {
  return { type: "CLEAR_REDIRECT" };
}
