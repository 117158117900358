import React, { Component } from "react";
import { connect } from "react-redux";
import requestImg from "./../../img/request.png";
import { Redirect } from "react-router-dom";

class Accepted extends Component {
  render() {
    let redirect = null;
    if (this.props.st_sentService.length < 1) {
      redirect = <Redirect to="/"></Redirect>;
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    });
    return (
      <div className="row" id="main">
        {redirect}
        {this.props.st_sentService.map(item => (
          <div className="col-sm-12 col-md-12" id="content">
            <div className="padeado">
              <h3 className="f2 fs-40">
                <strong>¡Tú solicitud ha sido creada con éxito!</strong>
              </h3>
              <p className="fs-20">
                Te enviaremos un correo electrónico cuando un técnico haya
                tomado tú solicitud.
              </p>
              <div className="mt-40 servicios">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="sh-l padeado20 pb-20"
                      style={{ backgroundColor: "#f7f7f7", paddingTop: "10px" }}
                    >
                      <div className="row">
                        <div className="col-xs-2">
                          <img
                            src={requestImg}
                            className="img-responsive"
                            alt=""
                          />
                        </div>
                        <div className="col-md-8">
                          <strong className="fs-22 f600">
                            Servicio solicitado
                          </strong>{" "}
                          <br />
                          <strong className="f600">Descripción</strong> <br />
                          {item.content_service} <br />
                          <strong className="f600">Fecha: </strong>
                          {item.datetime} <br />
                          <strong className="f600">Dirección: </strong>
                          {item.address.address +
                            " " +
                            item.address.description}{" "}
                          <br />
                          <strong className="f600">
                            Notas para el técnico:{" "}
                          </strong>{" "}
                          {item.aditional_notes} <br />
                          <strong className="f600">{item.notes}</strong>
                        </div>
                        <div className="col-md-2 text-right">
                          <p
                            className="text-right fs-22"
                            style={{ color: "#f2bb2f" }}
                          >
                            <small>{formatter.format(item.final_price)}</small>
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    st_sentService: state.information.sentService
  };
};

export default connect(mapStateToProps)(Accepted);
