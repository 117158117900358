import React, {Component} from 'react';


class HelpComponent extends Component{
    render(){
        return (
            <div className="row" id="main" >
                
            </div>
        )
    }
}

export default HelpComponent;