import React, { Component } from "react";
import { getCompleted } from "../../functions/InformationFunctions";
import ServiceLandscape from "../../components/ServiceLandscape";

class History extends Component {
  state = {
    services: [],
    loading: true,
  };
  render() {
    const updateHistorial = () => {
      this.setState({ loading: true });
      getCompleted()
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ services: res.data.success });
        })
        .catch((res) => {
          this.setState({ loading: false });
        });
    };
    if (this.state.services.length === 0 && this.state.loading) {
      getCompleted()
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ services: res.data.success });
        })
        .catch((res) => {
          this.setState({ loading: false });
        });
    }

    return (
      <div className="row" id="main">
        <div className="col-sm-12 col-md-12" id="content">
          <div className="padeado">
            <h3 className="f2 fs-40 fr-16 center-responsive">
              <strong>Historial de servicios</strong>
            </h3>
            <p className="fs-20 fr-12">
              A continuación encontraras información relacionada a tús servicios
              solicitados. Recuerda calificar tú servicio.
            </p>
            <div className="mt-40 mr-12 servicios">
              <div className="row">
                {this.state.loading ? (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>{" "}
                    <br />
                    <br />
                    Cargando
                  </div>
                ) : this.state.services.length > 0 ? (
                  this.state.services.map((item) => {
                    switch (item.request_service.state_id) {
                      case 1:
                        return (
                          <ServiceLandscape
                            key={item.request_service.id}
                            request={item.request_service}
                            address={item.address}
                            cancelable={true}
                            rateable={false}
                          ></ServiceLandscape>
                        );
                      case 2:
                        return (
                          <ServiceLandscape
                            key={item.request_service.id}
                            request={item.request_service}
                            address={item.address}
                            cancelable={false}
                            techName={
                              item.technician.name +
                              " " +
                              item.technician.last_name
                            }
                            techImage={item.technician.picture}
                            techBio={item.technician.bio}
                            rate={item.rate}
                            tech={false}
                            accepted={true}
                            rateable={false}
                          ></ServiceLandscape>
                        );
                      case 3:
                        return (
                          <ServiceLandscape
                            historialCallback={() => updateHistorial}
                            key={item.request_service.id}
                            request={item.request_service}
                            address={item.address}
                            cancelable={false}
                            rateable={item.request_service.rate_user === null}
                            techName={
                              item.technician.name +
                              " " +
                              item.technician.last_name
                            }
                            techImage={item.technician.picture}
                            techBio={item.technician.bio}
                            tech={true}
                          ></ServiceLandscape>
                        );
                      case 4:
                        return (
                          <ServiceLandscape
                            key={item.request_service.id}
                            request={item.request_service}
                            address={item.address}
                            cancelable={false}
                            rateable={false}
                          ></ServiceLandscape>
                        );

                      default:
                        break;
                    }
                  })
                ) : (
                  <div
                    className="text-center sh-l padeado20 pb-20 pt-20"
                    style={{ background: "#f3f3f3" }}
                  >
                    <i className="fa fa-times fa-3x fa-fw"></i> <br />
                    <br />
                    No tienes servicios aún
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default History;
