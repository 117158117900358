import axios from "axios";
import store from "../store";
import { serverlink } from "../enviroment";
import { updateCategories } from "../store/actions/informationActions";
import { setBanners, setMobileBanners } from "../store/actions/templateActions";
import { saveCart as saveCartAction } from "../store/actions/cartActions";
import { setProductCategories } from "../store/actions/productsActions";

export const getSystemCore = () => {
  return axios
    .post(serverlink + "/api/getinformation", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      store.dispatch(updateCategories(response.data.data.categories));
      store.dispatch(setBanners(response.data.data.banners));
      store.dispatch(setMobileBanners(response.data.data.mobile));
      store.dispatch(
        setProductCategories(response.data.data.product_categories)
      );
    });
};

export const getPendings = () => {
  return axios
    .post(serverlink + "/api/getpendings", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getAccepted = () => {
  return axios
    .post(serverlink + "/api/getaccepted", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getCanceled = () => {
  return axios
    .post(serverlink + "/api/getcanceled", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getCompleted = () => {
  return axios
    .post(serverlink + "/api/getcompleted", null, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("key"),
      },
    })
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getQualify = (service) => {
  return axios
    .post(
      serverlink + "/api/getqualify",
      { idService: service },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("key"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const getRequest = (request_id) => {
  return axios
    .post(
      serverlink + "/api/getrequest",
      { request_id: request_id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("key"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch(() => {
      return { success: false };
    });
};

export const saveCart = () => {
  store.dispatch(saveCartAction());
};
