export function addNewMessage(payload) {
  return {
    type: "ADD_MESSAGE",
    payload: payload,
  };
}

export function setMessages(messages) {
  return {
    type: "SET_MESSAGES",
    payload: messages,
  };
}

export function clearMessages() {
  return {
    type: "CLEAR_MESSAGES",
    payload: {},
  };
}
