import React, { Component } from "react";
import { loadUserData } from "./../../functions/AuthFunctions";
import { Redirect } from "react-router-dom";
import Logo from "../../img/logohp.png";
import Flip from "../../img/flipped.png";

class SocialAuth extends Component {
  state = {
    redirectHome: false,
    redirectCart: false,
  };

  componentDidMount() {
    setInterval(function () {
      try {
        document.getElementById("tableton").classList.toggle("hover");
      } catch (error) {}
    }, 1000);
  }

  render() {
    const { token } = this.props.match.params;

    localStorage.setItem("key", token);
    if (!this.state.redirectHome && !this.state.redirectCart) {
      loadUserData().then(() => {
        if (localStorage.getItem("cart") == null) {
          this.setState({ redirectHome: true });
        } else {
          this.setState({ redirectCart: true });
        }
      });
    }

    return (
      <div>
        {this.state.redirectHome ? <Redirect to="/"></Redirect> : null}
        {this.state.redirectCart ? (
          <Redirect to="/confirmar-compra"></Redirect>
        ) : null}
        <div className="col-md-6 col-md-offset-3">
          <div
            id="tableton"
            class="flip-container"
            style={{ margin: "0 auto", marginTop: "20vh" }}
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="flipper">
              <div class="front">
                <img src={Logo} />
              </div>
              <div class="back">
                <img src={Flip}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialAuth;
