import Pusher from "pusher-js";
import Swal from "sweetalert2";
import { addRedirect } from "../../store/actions/systemActions";
import { addNewMessage } from "../../store/actions/messagesActions";
import store from "../../store";

const logPusher = () => {
  Pusher.logToConsole = true;
};

const setPusher = (channel) => {
  var pusher = new Pusher("192809e0720d54b7f7d7", {
    cluster: "mt1",
  });
  var listen = pusher.subscribe(channel);
  listen.bind("new_message", function (data) {
    const currentRoute = window.location.pathname;

    if (currentRoute.includes("solicitud/")) {
      store.dispatch(addNewMessage(data.message));
      return;
    }

    console.log(data);
    Swal.fire({
      title: "Tienes un nuevo mensaje!",
      text: "El técnico desea comunicarte contigo",
      confirmButtonText: "Ver mensaje",
    }).then((result) => {
      if (result.value) {
        store.dispatch(addRedirect("/solicitud/" + data.message.request_id));
      }
    });
  });
  return pusher;
};

const PusherClass = { logPusher, setPusher };
export default PusherClass;
