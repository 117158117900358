import React, { Component } from "react";
import Payu from "../../img/payu.png";
import Cards from "../../img/creditcards.png";

class Credit extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.props.onEditCredit(e.target.name, e.target.value);
  }

  render() {
    return (
      <div className="mt-20">
        <script
          type="text/javascript"
          src={
            "https://maf.pagosonline.net/ws/fp/tags.js?id=" +
            this.props.sessionId +
            "80200"
          }
        ></script>

        <div className="raya"></div>
        <p className="mt-10 fs-18 fr-16">
          <strong className="f600">Datos de la tarjeta</strong>
        </p>
        <div className="form-group">
          <label className="f600">Nombre</label>
          <input
            name="name"
            type="text"
            className="form-control"
            placeholder="Escriba el nombre que aparece en la tarjeta"
            value={this.props.credit.name}
            onChange={this.onChange}
            required="required"
          />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label className="f600">Numero de la tarjeta</label>
              <input
                name="number"
                type="number"
                className="form-control"
                placeholder="Escriba el número de la tarjeta de crédito"
                value={this.props.credit.number}
                onChange={this.onChange}
                required="required"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="f600">CCV</label>
              <input
                name="ccv"
                value={this.props.credit.ccv}
                type="number"
                className="form-control"
                placeholder="CCV"
                onChange={this.onChange}
                required="required"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label className="f600">Fecha de vencimiento</label>
              <div className="row">
                <div className="col-xs-6">
                  <select
                    className="form-control"
                    name="month"
                    value={this.props.credit.month}
                    onChange={this.onChange}
                    required="required"
                  >
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
                <div className="col-xs-6">
                  <select
                    className="form-control"
                    name="year"
                    value={this.props.credit.year}
                    onChange={this.onChange}
                    required="required"
                  >
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="f600">Cuotas</label>
              <select
                className="form-control"
                name="installments"
                value={this.props.credit.installments}
                onChange={this.onChange}
                required="required"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <img src={Cards} alt="logos-cards" className="img-responsive" />
          </div>
          <div className="col-xs-8">
            <img src={Payu} alt="pay-u-logo" className="img-responsive" />
          </div>
        </div>
      </div>
    );
  }
}

export default Credit;
